import {
  ArrowDropDownCircleSharp,
  Notifications, Refresh,
  Wallet
} from '@mui/icons-material'
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Typography
} from '@mui/material'
import React from 'react'
import {dashboardAPI} from "../dashboard/DashboardAPI";
import {useAppSelector} from "../hooks";
import {isTokenExpired} from "../utils";
import {reset} from "../dashboard/dashboardSlice";
import {cryptoCopilotLocalStorageKey} from "../App";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}))

export type Coin = 'ETH' | 'DOGE' | 'AVAX' | 'ETC' | 'BTC' | string | 'XLM' | 'BCH' | 'LINK' | 'UNI'
interface CoinInfo {
  totalGainsInCrypto: string
  totalGainsInUSD: string
}
type props = {
  totalGainsEver: string
  totalGainsCash: string

  cryptoGains: {
    [K in Coin]: CoinInfo
  }
  plsRefresh: () => void
  setToLoading: () => void
}

export const TotalGainsCard = ({
  totalGainsEver,
  cryptoGains,
  totalGainsCash, plsRefresh, setToLoading
}: props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { profile } = useAppSelector(state => state.dashboard)

  const handleSignOut = () => {
    dispatch(reset())
    localStorage.removeItem(cryptoCopilotLocalStorageKey)
    navigate('/')
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Card sx={{ borderRadius: '25px' }}>
        <CardHeader
          avatar={
            <Box display="flex" alignItems="center">
              <Wallet />
              <Typography sx={{ marginLeft: '4px' }}>Crypto Gains</Typography>
              <IconButton onClick={handleClick} aria-label="settings">
                <ArrowDropDownCircleSharp />
              </IconButton>
            </Box>
          }
          action={
            <IconButton
              onClick={() =>{ setToLoading();if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
                handleSignOut()
              } dashboardAPI.refreshPlays(profile.accessToken).then(x => plsRefresh())}}
              aria-label="settings"
            >
              <Refresh />
            </IconButton>
          }
        />
        <CardContent sx={{ textAlign: 'left' }}>
          <Typography variant="h1">${totalGainsEver}</Typography>
          <Typography>Total Gains</Typography>
        </CardContent>
      </Card>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          ${totalGainsCash} USD
        </MenuItem>
        {Object.keys(cryptoGains).map(k => {
          const info: CoinInfo = cryptoGains[k as Coin]
          return (
            <MenuItem key={k} onClick={handleClose} disableRipple>
              <Typography sx={{ marginRight: '8px' }}>
                {k} - {info.totalGainsInCrypto} (${info.totalGainsInUSD} USD)
              </Typography>
            </MenuItem>
          )
        })}
      </StyledMenu>
    </>
  )
}
