import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  Button, Chip,
  CircularProgress,
  Fab, InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField, ToggleButton, ToggleButtonGroup,
  Typography
} from '@mui/material'
import { Asset, dashboardAPI } from '../dashboard/DashboardAPI'
import { FundEntityForPostRequest } from '../dashboard/Funds'
import { useAppSelector } from '../hooks'
import Grid from "@mui/material/Grid";
import PaidIcon from "@mui/icons-material/Paid";


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
}

export interface AddFundModalRefresh {
  refresh(): void
  isOpen: boolean
  handleClose: () => void
}

export default function AddFundModal({
  handleClose,
  refresh,
  isOpen
}: AddFundModalRefresh) {

  const {  marketApps } = useAppSelector(
      (state) => state.dashboard
  )
  const [asset, setAsset] = React.useState<Asset>('cash')
  const [marketApp, setMarketApp] = React.useState<string>( marketApps.apps.length > 0 ? marketApps.apps[0] : 'robinhood')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [fundExpiringSetting, setFundExpiringSetting] = React.useState<string>("one_time_use")
  const [amountInUsd, setAmountInUsd] = React.useState<string>("1.0")
  const [quantityOfFunds, setQuantityOfFunds] = React.useState<string>("1")
  const [totalAmountInvestedString, setTotalAmountInvestedString] = React.useState<string>("1")
  const { profile } = useAppSelector(state => state.dashboard)

  const handleChangeAssetType = (event: SelectChangeEvent) => {
    setAsset(event.target.value as Asset)
  }

  const handleChangeMarketApp = (event: SelectChangeEvent) => {
    setMarketApp(event.target.value as string)
    setAsset("cash")
  }

  const handleFundExpiringSetting = (
      event: React.MouseEvent<HTMLElement>,
      newFormats: string
  ) => {
    setFundExpiringSetting(newFormats)
  }


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    console.log('in the onsubmit')
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const value: FundEntityForPostRequest = {
      // user: profile.email,
      status: 'open',
      asset: data.get('asset'),
      amount_in_fiat_currency: data.get('amount_in_usd'),
      number_of_funds_to_make: data.get('number_of_funds_to_make'),
      market_app: data.get('market_app'),
      currency: data.get('market_app') == 'robinhood' ? 'usd' : 'mxn',
      fund_expiring_setting: data.get('fund_expiring_setting')

    }
    if (
      typeof value.amount_in_fiat_currency === 'string' &&
      !value.amount_in_fiat_currency.endsWith('.00')
    ) {
      value.amount_in_fiat_currency = (
        Math.round(parseFloat(value.amount_in_fiat_currency) * 100) / 100
      ).toFixed(2)
    }
    console.log('data for new fund')
    console.log(value)
    console.log(typeof value.amount_in_fiat_currency)
    console.log('data for new fund')
    dashboardAPI
      .postFunds( profile.accessToken, value)
      .then(x => {
        handleClose()
        refresh()
        setIsLoading(false)
        handleClose()
      })
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, width: 400 }}
          component="form"
          noValidate
          onSubmit={handleSubmit}
        >
          <Typography sx={{ marginLeft: '16px' }} variant="h5">
            Create Fund
          </Typography>

          <InputLabel id="label-market">Market App</InputLabel>
          {marketApps.apps.length > 1 ?
          <Select
              labelId="asset-filter-select"
              id="market_app"
              name="market_app"
              value={marketApp}
              aria-labelledby={'label-market'}

              onChange={handleChangeMarketApp}
              fullWidth
          >
            <MenuItem disabled={!marketApps.apps.includes('robinhood')} value={'robinhood'}>Robinhood</MenuItem>
            <MenuItem disabled={!marketApps.apps.includes('bitso')} value={'bitso'}>Bitso</MenuItem>

          </Select>
              :
              <Chip aria-labelledby={'label-market'} color={'secondary'} label={marketApp}/>
          }

          <InputLabel id="label-asset">Asset Type</InputLabel>

          <Select
            labelId="asset-filter-select"
            id="asset"
            name="asset"
            value={asset}
            aria-labelledby={'label-asset'}
            onChange={handleChangeAssetType}
            fullWidth
          >
            <MenuItem value={'cash'}>Cash</MenuItem>
            <MenuItem value={'ETH'}>ETH</MenuItem>
            <MenuItem value={'BTC'}>BTC</MenuItem>
            {marketApp === 'robinhood' &&<MenuItem value={'DOGE'}>DOGE</MenuItem>}
            {marketApp === 'robinhood' &&<MenuItem value={'ETC'}>ETC</MenuItem>}
            <MenuItem value={'AVAX'}>AVAX</MenuItem>

            {marketApp === 'robinhood' && <MenuItem value={'XLM'}>XLM</MenuItem>}
            <MenuItem value={'BCH'}>BCH</MenuItem>
            {marketApp === 'robinhood' && <MenuItem value={'LINK'}>LINK</MenuItem>}
            {marketApp === 'robinhood' && <MenuItem value={'UNI'}>UNI</MenuItem>}
          </Select>

          {asset === 'cash'&& <Alert severity={'info'}>Funding for BULL Plays 📈</Alert>}
          {asset !== 'cash'&& <Alert severity={'info'}>Funding for BEAR Plays 📉</Alert>}

          <InputLabel id="label-amount">Fund Amount in {marketApp === 'robinhood' ? 'USD' : 'MXN'}</InputLabel>

          <TextField
            margin="normal"
            required
            fullWidth
            name="amount_in_usd"
            aria-labelledby={'label-amount'}

            id="amount_in_usd"
            type="number"
            inputProps={{ step: '0.01', lang: 'en-US' }}
            value={amountInUsd}
            onChange={
              (event) => {

                const { value } = event.target;
                setAmountInUsd(value);
                setTotalAmountInvestedString((parseFloat(value)*parseFloat(quantityOfFunds)).toFixed(2) )

              }}
          />

          <InputLabel id="label-quantity">Quantity Of Funds</InputLabel>


          <TextField
              margin="normal"
              required
              fullWidth
              name="number_of_funds_to_make"
              aria-labelledby={'label-quantity'}
              id="number_of_funds_to_make"
              type="number"
              defaultValue={1}
              inputProps={{ step: '1', lang: 'en-US' }}
              value={quantityOfFunds}
              onChange={
                (event) => {

                  const { value } = event.target;
                  setQuantityOfFunds(value);
                  setTotalAmountInvestedString((parseFloat(value)*parseFloat(amountInUsd)).toFixed(2))


                }}
          />
          <InputLabel id="label-total-investment">Total Investment</InputLabel>

          <Chip color={'primary'} variant={'outlined'} icon={<PaidIcon/>} size={'medium'} aria-labelledby={'label-total-investment'} label={ totalAmountInvestedString}/>
          <br/>
          <ToggleButtonGroup
              color="primary"
              value={fundExpiringSetting}
              exclusive
              onChange={handleFundExpiringSetting}
              id="fund_expiring_setting"
              size={"small"}
          >
            <ToggleButton value="one_time_use">One play use</ToggleButton>
            <ToggleButton  value="non_expirable">Non-Expiring</ToggleButton>
          </ToggleButtonGroup>
          {fundExpiringSetting === 'one_time_use' &&  <Alert severity={'info'}>After successful play, fund is released</Alert>}
          {fundExpiringSetting === 'non_expirable' &&  <Alert severity={'info'}>CryptoCopilot continues to reinvest fund</Alert>}
          <Grid container spacing={2} >

            <Grid item xs={6}>
              <Button
                  color="secondary"

                  fullWidth

                  onClick={()=> handleClose()}
                  variant="outlined"
                  sx={{ mt: 3, mb: 2 }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
          <Button
            fullWidth
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress color={'secondary'} />}
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create
          </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}
