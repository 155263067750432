import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { GoogleLogin } from '@react-oauth/google'

import { useNavigate } from 'react-router-dom'
import { reset, setProfile } from '../dashboard/dashboardSlice'
import { useAppDispatch, useAppSelector } from '../hooks'
import { cryptoCopilotLocalStorageKey, clientId } from '../App'
import { isTokenExpired } from '../utils'
import { Button } from '@mui/material'
import { useState } from 'react'

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://cryptocopilot.netlify.com/">
        Crypto Copilot
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

interface Props {}

export default function SignInSide({}: Props) {
  const navigate = useNavigate()
  const { profile } = useAppSelector((state) => state.dashboard)
  const dispatch = useAppDispatch()
  const [isTokenExpiredRn, setIsTokenExpiredRn] = useState(
    profile.accessTokenExpiresAt == null ||
      isTokenExpired(profile.accessTokenExpiresAt)
  )

  // navigate to dashboard when token is still valid
  React.useEffect(() => {
    if (!isTokenExpiredRn && profile.accessToken !== '') {
      navigate('/dashboard')
    }
  }, [profile.accessTokenExpiresAt, isTokenExpiredRn])

  const handleSuccess = (res: any) => {
    const resTyped = { ...res }
    const accessTokenExpiresAt = Date.now() + 4 * 3600 * 1000

    const accessToken = resTyped.credential

    const profile = { accessToken, accessTokenExpiresAt }
    console.log(accessToken)
    localStorage.setItem(cryptoCopilotLocalStorageKey, JSON.stringify(profile))

    dispatch(setProfile(profile))
    navigate('/dashboard')
  }

  const handleFailure = (err: any) => {
    console.log('failed signin', err)
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1640340434855-6084b1f4901c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2000&q=80)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            {profile.accessToken}
            {profile.accessTokenExpiresAt}
            {!isTokenExpiredRn ? (
              <div>
                <h3>User Logged in</h3>
                <br />
                <br />
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(reset())
                    setIsTokenExpiredRn(true)
                    console.log('signout')
                  }}
                >
                  Sign Out
                </Button>
              </div>
            ) : (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleSuccess(credentialResponse)
                  setIsTokenExpiredRn(false)
                }}
                onError={() => {
                  handleFailure('blah')
                }}
                useOneTap
                ux_mode={'redirect'}
                //login_uri="https://us-central1-demolisherapp.cloudfunctions.net/crypto_bot_http_backend/google_redirect_call_local"
                login_uri="https://us-central1-demolisherapp.cloudfunctions.net/crypto_bot_http_backend/google_redirect_call"
              />
            )}

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
