import {
    Box, Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Grid, InputLabel,
    ListItem,
    ListItemAvatar,
    ListItemText, Rating,
    Typography
} from '@mui/material'
import React from 'react'
import { PlayEntity } from '../dashboard/Plays'
import EditPlayModal from './EditPlayModal'
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import PaidIcon from "@mui/icons-material/Paid";
import {useLocation, useNavigate} from "react-router-dom";


const cryptoIconMap = {
  ETH:
    'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/eth.svg',
  DOGE:
    'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/doge.svg',
  BTC:
    'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/btc.svg',
  AVAX:
    'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/avax.svg',
    XLM:
        'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/xlm.svg',
    BCH:
        'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/bch.svg',
    LINK:
        'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/link.svg',
    UNI:
        'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/uni.svg',
  ETC:
    'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/etc.svg',
  default:
    'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/grin.svg'
}

export default function PlayListItem(
  {
    amount_in_usd,
    create_ts,
    crypto_symbol,
    ending_asset,
    ending_price,
    ending_transaction,
    ending_quantity,
    funding_id,
    id,
    price,
    quantity,
    starting_order_has_been_fulfilled,
    starting_transaction,
    starting_transaction_type,
    status,
    updated_price_on_order_fulfilment,
    user,
    last_upd_ts,
    refresh,
    gains,
    gains_string,
    gains_asset,
    gains_in_usd,play_source_label,
      market_app, detailed_status, amount_in_mxn
  }: PlayEntity
) {
    const navigate = useNavigate()
    const location = useLocation();




    let playGains = 0.0;
    if(starting_transaction_type === 'buy') {
        playGains = ending_price/parseFloat(price) - 1;
    }

    if(starting_transaction_type === 'sell') {
        playGains = parseFloat(price)/ending_price - 1;
    }
    let startingTransactionInfo = ''
    let endingTransactionInfo = ''
    if(market_app === 'robinhood' && starting_transaction_type === 'buy') {
        startingTransactionInfo = 'BUY ' + amount_in_usd.toFixed(2) + " USD";
        endingTransactionInfo = 'SELL ' + (amount_in_usd*(1 + playGains)).toFixed(2) + " USD";
    }
    if(market_app === 'robinhood' && starting_transaction_type === 'sell') {
        startingTransactionInfo = 'SELL ' + amount_in_usd.toFixed(2) + " USD";
        endingTransactionInfo = 'BUY ' + (amount_in_usd*(1 + playGains)).toFixed(2) + " USD";
    }
    if(market_app === 'bitso' && starting_transaction_type === 'buy') {
        if(amount_in_mxn) {
             startingTransactionInfo = 'BUY ' + parseFloat( amount_in_mxn).toFixed(0) + " MXN";
             endingTransactionInfo = 'SELL ' + (parseFloat(amount_in_mxn) * (1 + playGains)).toFixed(0) + " MXN";
        }
    }
    if(market_app === 'bitso' && starting_transaction_type === 'sell') {
        if(amount_in_mxn) {
            startingTransactionInfo = 'SELL ' + parseFloat(amount_in_mxn).toFixed(0) + " MXN";
            endingTransactionInfo = 'BUY ' + (parseFloat(amount_in_mxn) * (1 + playGains)).toFixed(0) + " MXN";
        }
    }

    let riskMetric = 0.0
    if(playGains < 0.03){
        riskMetric =1.0
    }

    else if(playGains < 0.05){
        riskMetric =2.0
    }

    else if(playGains < 0.07){
        riskMetric =3.0
    }

    else if(playGains < 0.09){
        riskMetric =4.0
    }
    else {
        riskMetric = 5.0
    }
    let rewardMetric = 0.0
    if(market_app === 'robinhood') {
        rewardMetric = amount_in_usd * playGains
    }
    if(market_app === 'bitso') {
        rewardMetric = parseFloat(amount_in_mxn) * playGains
    }

  const isPlayClosed = status === 'closed'
  let timestamp : string




  if (status == 'closed') {
    const timeLabel = new Date(last_upd_ts)
    timestamp = `Closed at: ${timeLabel.toLocaleDateString()} ${timeLabel.toLocaleTimeString()}`
  } else {
    const timeLabel = new Date(create_ts)
    timestamp = `Created at: ${timeLabel.toLocaleDateString()} ${timeLabel.toLocaleTimeString()}`

  }

  const TransactionCardHeader = (): JSX.Element => {
    let src = cryptoIconMap['default']
    if (crypto_symbol in cryptoIconMap) {
      src = cryptoIconMap[crypto_symbol as keyof typeof cryptoIconMap]
    }

    return (
      <Grid container spacing={2} alignItems={'center'}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={src} alt={crypto_symbol} />{' '}
              <Typography sx={{ margin: '0px 8px' }} variant="h5">
                  {crypto_symbol}
              </Typography>
          </Box>

      </Grid>
    )
  }

  let took = (Date.parse(last_upd_ts) - Date.parse( create_ts))
    let took_in_hours = true;
    let took_number = (took/(60*60*1000)).toFixed(2);
    if(took/(60*60*1000) > 50) {
        took_number = (took/(24*60*60*1000)).toFixed(2);
        took_in_hours = false;
    }

    const getDetailedStatus = () => {
        if(detailed_status !== undefined) {
            if (detailed_status.ending_transaction_has_been_fulfilled !== undefined) {
                return <Chip size="small" label={'Play Completed'} color="info"/>
            }
            if (detailed_status.ending_transaction_submitted !== undefined) {
                return <Chip size="small" label={'Awaiting Ending Transaction Fulfillment'} color="info"/>
            }
            if (detailed_status.starting_transaction_has_been_fulfilled !== undefined) {
                return <Chip size="small" label={'Starting Transaction Fulfilled'} color="info"/>
            }
            if (detailed_status.starting_transaction_submitted !== undefined) {
                return <Chip size="small" label={'Awaiting Starting Transaction Fulfillment'} color="info"/>
            }
        }
    }




    return (
    <Card sx={{ marginBottom: '16px' }} raised>
      <CardHeader avatar={TransactionCardHeader()} />
      <CardContent>
          <Typography variant="caption">{timestamp}</Typography>

          <Grid container spacing={1}>
          {/* TODO: wrap this with a tooltip to explain to the user what is a 
                  bull/bear play and what that means to what assets they hold. 
                  More cash? More Crypto? */}
          <Grid item xs={12}>

              {!location.pathname.includes('/dashboard/play/') ? <>
              {isPlayClosed ?

                  <Button variant="outlined"  color={'info'} onClick={ ()=> navigate('/dashboard/play/' + id)} >View <QueryStatsIcon/></Button>

                  : <Grid container spacing={2} >
                  <Grid item xs={6}>

                  <Button variant="outlined" color={'info'} onClick={ ()=> navigate('/dashboard/play/' + id)} >View <QueryStatsIcon/></Button>
                  </Grid>
                                <Grid item xs={6}>
              <EditPlayModal
                  amount_in_usd={amount_in_usd}
                  amount_in_mxn={amount_in_mxn}
                  create_ts={create_ts}
                  crypto_symbol={crypto_symbol}
                  ending_asset={ending_asset}
                  ending_price={ending_price}
                  ending_transaction={ending_transaction}
                  ending_quantity={ending_quantity}
                  funding_id={funding_id}
                  id={id}
                  price={price}
                  quantity={quantity}
                  starting_order_has_been_fulfilled={
                      starting_order_has_been_fulfilled
                  }
                  starting_transaction={starting_transaction}
                  starting_transaction_type={starting_transaction_type}
                  status={status}
                  updated_price_on_order_fulfilment={
                      updated_price_on_order_fulfilment
                  }
                  user={user}
                  last_upd_ts={last_upd_ts}
                  refresh={refresh}
                  gains={gains}
                  gains_string={gains_string}
                  gains_asset={gains_asset}
                  gains_in_usd={gains_in_usd}
                  play_source_label={play_source_label}
                  market_app={market_app}
              /> </Grid>
              </Grid>
              }
              </>
              :
                  <>
                  { !isPlayClosed &&
                  <EditPlayModal
                      amount_in_usd={amount_in_usd}
                      amount_in_mxn={amount_in_mxn}
                      create_ts={create_ts}
                      crypto_symbol={crypto_symbol}
                      ending_asset={ending_asset}
                      ending_price={ending_price}
                      ending_transaction={ending_transaction}
                      ending_quantity={ending_quantity}
                      funding_id={funding_id}
                      id={id}
                      price={price}
                      quantity={quantity}
                      starting_order_has_been_fulfilled={
                          starting_order_has_been_fulfilled
                      }
                      starting_transaction={starting_transaction}
                      starting_transaction_type={starting_transaction_type}
                      status={status}
                      updated_price_on_order_fulfilment={
                          updated_price_on_order_fulfilment
                      }
                      user={user}
                      last_upd_ts={last_upd_ts}
                      refresh={refresh}
                      gains={gains}
                      gains_string={gains_string}
                      gains_asset={gains_asset}
                      gains_in_usd={gains_in_usd}
                      play_source_label={play_source_label}
                      market_app={market_app}
                  />}
                  </>
              }
          </Grid>
          <Grid item xs={12}>

              <InputLabel id="label-play-strategy">{starting_transaction_type === 'buy' ?'📈BULL' : "📉BEAR"} Strategy</InputLabel>

              <Grid aria-labelledby={"label-play-strategy"} container spacing={2} >
                  <Grid item xs={6}>
                      <Chip color={starting_transaction_type === 'buy' ? 'success' : 'error'} label={startingTransactionInfo} variant={ starting_order_has_been_fulfilled !== undefined && starting_order_has_been_fulfilled ? "filled": "outlined"} />
                      {/*<Box><Typography variant="button">{startingTransactionInfo}</Typography></Box>*/}
                  </Grid>


                  <Grid item xs={6}>
                      <Chip color={starting_transaction_type === 'buy' ? 'error' : 'success'}  label={endingTransactionInfo} variant={detailed_status !== undefined && detailed_status.ending_transaction_has_been_fulfilled !== undefined ? "filled": "outlined"} />
                      {/*<Box><Typography variant="button">{endingTransactionInfo}</Typography></Box>*/}
                  </Grid>


              </Grid>

              <Grid container spacing={8} >
                  <Grid item xs={4}>
                      <Rating
                          icon={<MonetizationOnIcon fontSize="inherit" />}
                          emptyIcon={<AttachMoneyIcon fontSize="inherit" />}

                          name="half-rating-read" value={riskMetric} precision={0.5} readOnly />
                      <Box><Typography variant="subtitle2"><strong>Risk</strong></Typography></Box>
                  </Grid>
                          < Grid item xs={4}>

                              <Box><PercentIcon/> <Typography variant="subtitle2"><strong>{(playGains*100).toFixed(0)}</strong></Typography></Box>
                          </Grid>
                          < Grid item xs={4}>

                              <Box><PaidIcon  /><Typography variant="subtitle2"><strong>{rewardMetric.toFixed(2)} {market_app === 'robinhood' ? 'USD' : 'MXN'}</strong></Typography></Box>
                          </Grid>
              </Grid>




                  <Box sx={{ margin: '0 8px' }}> { play_source_label && <Chip size="small" label={play_source_label} color="info" />}
                      { market_app && <Chip size="small" label={market_app} color="info" />}
                      { detailed_status && getDetailedStatus()}

                  </Box>
              <Typography sx={{ color: '#5bc04f', fontWeight: 'bold' }}>
                  { took_in_hours && isPlayClosed ?'Duration: ' + took_number + " hours" :  'Duration: ' + took_number + " days" }
              </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
