import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Popover,
    Rating,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../hooks'
import {Asset, dashboardAPI} from './DashboardAPI'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import {useNavigate} from "react-router-dom";
import {isTokenExpired} from "../utils";
import {reset} from "./dashboardSlice";
import {cryptoCopilotLocalStorageKey} from "../App";
import {useDispatch} from "react-redux";
import {FundEntity} from "./Funds";
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaidIcon from '@mui/icons-material/Paid';
import {AppBarWithDrawer} from "../shared/AppBarWithDrawer";
export interface OnDemandPlay {
    asset: string
    play_type: string
    fund_id: string
    amount_in_fiat_currency: null | FormDataEntryValue
    starting_price : string
    ending_price: string
    market_app: string
}

const styles = {
    position:'absolute',
    top:'10%',
    left:'10%',
    overflow:'scroll',
    height:'100%',
    display:'block'
};
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
}


export default function MakePlay() {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const { profile ,marketApps, exchangePrices } = useAppSelector(state => state.dashboard)



    const [funds, setFunds] = React.useState<FundEntity[]>([])
    const [fundId, setFundId] = React.useState<string>('')
    const [asset, setAsset] = React.useState<Asset>('ETH')
    const [playType, setPlayType] = React.useState<string>('BULL')
    const [startingPrice, setStartingPrice] = React.useState<string>("market_price")
    const [endingPrice, setEndingPrice] = React.useState<string>('0.05')
    const [marketApp, setMarketApp] = React.useState<string>( marketApps.apps.length > 0 ? marketApps.apps[0] : 'robinhood')
    const [existingOrNewFund, setExistingOrNewFund] = React.useState<string>('existing')
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [amountInUsd, setAmountInUsd ] = React.useState<string>("1.0")
    const [minBetSize, setMinBetSize ] = React.useState<number>(0.0)
    const [minPlayGains, setMinPlayGains ] = React.useState<number>(0.0)
    const [betSize, setBetSize ] = React.useState<number>(0.0)
    const [riskMetric, setRiskMetric ] = React.useState<number>(0.0)
    const [rewardMetric, setRewardMetric ] = React.useState<number>(0.0)
    const [playGains, setPlayGains ] = React.useState<number>(0.0)

    const [startingTransactionInfo, setStartingTransactionInfo] = React.useState<string>('')
    const [endingTransactionInfo, setEndingTransactionInfo] = React.useState<string>('')



    useEffect(
        () => {


            let betSizeNew = 0.0
            if(existingOrNewFund !== 'new') {
                for(let i = 0 ; i < funds.length; i++) {
                    if(funds[i].id === fundId){
                        if(playType === 'BEAR') {
                            if(funds[i].amount_in_crypto !== undefined) {
                                betSizeNew = parseFloat( funds[i].amount_in_crypto)
                            }
                            break;
                        }
                        else if(marketApp === 'robinhood') {
                            if(funds[i].amount_in_usd !== undefined) {
                                betSizeNew = funds[i].amount_in_usd
                            }
                            break;
                        }
                        else if(marketApp === 'bitso') {
                            if(funds[i].amount_in_mxn !== undefined) {
                                betSizeNew = funds[i].amount_in_mxn
                            }
                            break;
                        }
                    }
                }
            }
            else {
                betSizeNew = parseFloat( amountInUsd);
                if(isNaN(betSizeNew)){
                    betSizeNew = 0.0;
                }
            }

            let startingPrice2 = 0.0;
            if(startingPrice === 'market_price') {
                startingPrice2 = 1.0;
            } else if(playType === 'BULL'){

                startingPrice2 = 1.0 - parseFloat( startingPrice);
            }
            else if(playType === 'BEAR'){
                startingPrice2 = 1.0 + parseFloat( startingPrice);
            }
            let endingPrice2 = 0.0
            if(playType === 'BULL') {
                endingPrice2 = 1 + parseFloat(endingPrice);
            }
            else if(playType === 'BEAR') {
                endingPrice2 = 1 - parseFloat(endingPrice);
            }

            let startingPriceInfo = '';
            let endingPriceInfo = '';




            let minBetSizeNew = 0.0
            let minPlayGainsNew = 0.0

            if(playType === 'BULL' && marketApp === 'bitso') {

                minBetSizeNew = 2.1 / ((endingPrice2 / startingPrice2) - 1.0)
                if(betSizeNew > 0.0) {
                    minPlayGainsNew = (2.2 / betSizeNew) + 1
                }
            }
            if(playType === 'BEAR' && marketApp === 'bitso'){
                minBetSizeNew = 3.1 / (1.0 - (endingPrice2/ startingPrice2))
                if(betSizeNew > 0.0) {
                    minPlayGainsNew =  1 - (3.2 / betSizeNew)
                }
            }



            if(playType === 'BULL' && marketApp === 'robinhood') {

                minBetSizeNew = 0.05 / ((endingPrice2 / startingPrice2) - 1.0)
                if(betSizeNew > 0.0) {
                    minPlayGainsNew = (0.06 / betSizeNew) + 1
                }
            }
            if(playType === 'BEAR' && marketApp === 'robinhood'){
                minBetSizeNew = 0.07 / (1.0 - (endingPrice2/ startingPrice2))
                if(betSizeNew > 0.0) {
                    minPlayGainsNew =  1 - (0.08 / betSizeNew)
                }
            }

                if(minPlayGainsNew > 0.0){
                    minPlayGainsNew = minPlayGainsNew - 1
                }

            setBetSize(betSizeNew)
            setMinBetSize(minBetSizeNew);
            setMinPlayGains(minPlayGainsNew*100)

            let exchangePrice = 0.0;
            if(asset === 'ETH'){
                exchangePrice = exchangePrices.ETH
            }
            if(asset === 'DOGE'){
                exchangePrice = exchangePrices.DOGE
            }if(asset === 'BTC'){
                exchangePrice = exchangePrices.BTC
            }if(asset === 'BCH'){
                exchangePrice = exchangePrices.BCH
            }if(asset === 'UNI'){
                exchangePrice = exchangePrices.UNI
            }if(asset === 'LINK'){
                exchangePrice = exchangePrices.LINK
            }if(asset === 'XLM'){
                exchangePrice = exchangePrices.XLM
            }

            let betAmount = "";
            if(marketApp === 'robinhood') { betAmount = betSizeNew.toFixed(2) + " USD" }
            if(marketApp === 'bitso') { betAmount = betSizeNew.toFixed(2) + " MXN" }

                if(betSizeNew == 0.0) {
                    if (playType === 'BULL') {
                        startingPriceInfo = 'Buy@' + (startingPrice2 * exchangePrice).toFixed(2);
                        endingPriceInfo = 'Sell@' + (endingPrice2 * exchangePrice).toFixed(2);
                    }
                    if (playType === 'BEAR') {
                        startingPriceInfo = 'Sell@' + (startingPrice2 * exchangePrice).toFixed(2);
                        endingPriceInfo = 'Buy@' + (endingPrice2 * exchangePrice).toFixed(2);
                    }
                }



            let rewardNew = 0.0;
            if(playType === 'BULL'){
                if(endingPrice2 - startingPrice2 < 0.03) {
                    setRiskMetric(1.0)
                }
                else if(endingPrice2 - startingPrice2 < 0.05) {
                    setRiskMetric(2.0)
                }

                else if(endingPrice2 - startingPrice2 < 0.07) {
                    setRiskMetric(3.0)
                }


                else if(endingPrice2 - startingPrice2 < 0.09) {
                    setRiskMetric(4.0)
                }
                else{
                    setRiskMetric(5.0)
                }
                if(betSizeNew !== 0.0){
                    rewardNew = (endingPrice2 - startingPrice2)*betSizeNew


                }
                setPlayGains((endingPrice2 - startingPrice2)*100)

            }


            if(playType === 'BEAR'){
                if(startingPrice2 - endingPrice2 < 0.03) {
                    setRiskMetric(5.0)
                }
                else if(startingPrice2 - endingPrice2 < 0.05) {
                    setRiskMetric(4.0)
                }

                else if(startingPrice2 - endingPrice2 < 0.07) {
                    setRiskMetric(3.0)
                }


                else if(startingPrice2 - endingPrice2 < 0.09) {
                    setRiskMetric(2.0)
                }
                else{
                    setRiskMetric(1.0)
                }
                if(betSizeNew !== 0.0){
                    rewardNew = (startingPrice2 - endingPrice2)*betSizeNew

                }
                setPlayGains((startingPrice2 - endingPrice2)*100)

            }
            setRewardMetric(rewardNew)

            let endAmount = '';

            if(marketApp === 'robinhood') { endAmount = (betSizeNew + rewardNew).toFixed(2) + " USD" }
            if(marketApp === 'bitso') { endAmount = (betSizeNew + rewardNew).toFixed(2) + " MXN" }


            if(betSizeNew > 0.0) {
                if (playType === 'BULL') {
                    startingPriceInfo = 'Buy '+ betAmount ;
                    endingPriceInfo = 'Sell '+ endAmount  ;
                }
                if (playType === 'BEAR') {
                    startingPriceInfo = 'Sell ' + betAmount;
                    endingPriceInfo = 'Buy '+ endAmount ;
                }
            }


            setStartingTransactionInfo(startingPriceInfo)
            setEndingTransactionInfo(endingPriceInfo)

        }, [amountInUsd, existingOrNewFund, startingPrice, endingPrice, marketApp, fundId, asset, playType, marketApps.apps]

    )


    useEffect(() => {
        async function getFunds() {
            setIsLoading(true);
            if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
                handleSignOut()
            }
            if(profile.accessToken !== "") {
                const res2 = await dashboardAPI.getFunds(
                    // profile.email,
                    profile.accessToken, marketApp, playType === 'BULL' ? 'cash' : asset
                )
                const fundsData: FundEntity[] = res2 === undefined ? [] : res2.data
                console.log('getFunds res2', fundsData)

                setFunds(fundsData)
                if(fundsData.length > 0) {
                    setFundId(fundsData[fundsData.length - 1 ].id)
                }
                if(fundsData.length === 0 ){
                    setExistingOrNewFund('new')
                }

            }
            setIsLoading(false);
        }

        getFunds()

    }, [profile.accessToken, marketApp, playType, asset]);



    const handleChangeAssetType = (event: SelectChangeEvent) => {
        setAsset(event.target.value as Asset)
    }

    const handleChangeFundId = (event: SelectChangeEvent) => {

        console.log(event.target.value)

        setFundId(event.target.value as string)
    }

    const handlePlaySelect = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string
    ) => {
        setPlayType(newFormats)
        setFundId('')
    }


    const handleMarketAppSelect = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string
    ) => {
        setMarketApp(newFormats)
        setAsset("BTC")
        setFundId('')
    }


    const handleStartingPriceSelect = (
        event: SelectChangeEvent
    ) => {
        setStartingPrice(event.target.value)

    }
    const handleEndingPriceSelect = (
        event: SelectChangeEvent
    ) => {
        setEndingPrice(event.target.value)

    }

    const handleUseExistingOrNewFund = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string
    ) => {
        setExistingOrNewFund(newFormats)
        if(newFormats === 'new') {
            setFundId('')
        }
    }
    const handleSignOut = () => {
        dispatch(reset())
        localStorage.removeItem(cryptoCopilotLocalStorageKey)
        navigate('/')
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true)
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const value: OnDemandPlay = {
            asset: asset,
            play_type: playType,
            fund_id: fundId,
            amount_in_fiat_currency: data.get('amount_in_fiat_currency'),
            starting_price: startingPrice,
            ending_price: endingPrice,
            market_app: marketApp
        }

        if (
            typeof value.amount_in_fiat_currency === 'string' &&
            !value.amount_in_fiat_currency.endsWith('.00')
        ) {
            value.amount_in_fiat_currency = (
                Math.round(parseFloat(value.amount_in_fiat_currency) * 100) / 100
            ).toFixed(2)
        }

        if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
            handleSignOut()
        }

        dashboardAPI.makePlay( profile.accessToken, value).then(x => {


            setIsLoading(false)

            setFundId('')

            if(x !== undefined && x.data.play_id !== undefined && x.data.play_id !== null) {
                navigate('/dashboard/play/' + x.data.play_id)
            }
        })
    }

    return (
        <>
        <Container
            component="form"
                    noValidate
                    onSubmit={handleSubmit}
                >
            <Typography sx={{ marginLeft: '16px' }} variant="h5">
                Make Play
            </Typography>

            <Grid container spacing={1} style={{textAlign: "center"}} >
                <Grid item xs={6} >

                    <InputLabel id="label-market">Market App</InputLabel>
                    { marketApps.apps.length === 1 ?
                        <Chip label={marketApp}/>
                        :
                        <ToggleButtonGroup
                            color="primary"
                            value={marketApp}
                            exclusive
                            onChange={handleMarketAppSelect}
                            id="market_app"
                            aria-labelledby={'label-market'}
                            size={"small"}
                        >
                            <ToggleButton disabled={!marketApps.apps.includes('robinhood')}
                                          value="robinhood">Robinhood</ToggleButton>
                            <ToggleButton disabled={!marketApps.apps.includes('bitso')}
                                          value="bitso">Bitso</ToggleButton>
                        </ToggleButtonGroup>


                    }
                </Grid>



                <Grid item xs={6} >

                    <InputLabel id="label">Crypto</InputLabel>

                    <Select
                        labelId="label"
                        id="asset"
                        name="asset"
                        value={asset}
                        size={'small'}
                        fullWidth
                        onChange={handleChangeAssetType}
                    >
                        <MenuItem value={'ETH'}>ETH</MenuItem>
                        <MenuItem value={'BTC'}>BTC</MenuItem>
                        {marketApp === 'robinhood' && <MenuItem value={'DOGE'}>DOGE</MenuItem>}
                        <MenuItem value={'AVAX'}>AVAX</MenuItem>

                        {marketApp === 'robinhood' &&<MenuItem value={'ETC'}>ETC</MenuItem>}


                        {marketApp === 'robinhood' && <MenuItem value={'XLM'}>XLM</MenuItem>}
                        <MenuItem value={'BCH'}>BCH</MenuItem>
                        {marketApp === 'robinhood' && <MenuItem value={'LINK'}>LINK</MenuItem>}
                        {marketApp === 'robinhood' && <MenuItem value={'UNI'}>UNI</MenuItem>}
                    </Select>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{textAlign: "center"}} >
                <Grid item xs={4}>


                    <InputLabel id="label-play">Play Type</InputLabel>

                    <ToggleButtonGroup
                        color="primary"
                        value={playType}
                        exclusive
                        onChange={handlePlaySelect}
                        aria-label="Play Type"
                        id="play_type"
                        aria-labelledby={'label-play'}
                        size={'small'}

                    >
                        <ToggleButton value="BULL">BULL</ToggleButton>
                        <ToggleButton value="BEAR">BEAR</ToggleButton>
                    </ToggleButtonGroup>

                </Grid>
                <Grid item xs={6}>


                    <InputLabel id="label-prediction">Prediction</InputLabel>

                        {playType === 'BULL'&&
                            <Alert variant={'filled'}  icon={<TrendingUpIcon fontSize="inherit" />} severity="success">
                                {asset} Price Goes Up
                            </Alert>


                        }
                        {playType === 'BEAR'&&
                            <Alert variant={'filled'} icon={<TrendingDownIcon fontSize="inherit" />} severity="error">
                                {asset} Price Goes Down
                            </Alert>
                        }

                </Grid>

            </Grid>



            <InputLabel id="label-staring-price">Starting Price</InputLabel>


                    <Select
                        color="primary"
                        value={startingPrice}

                        onChange={handleStartingPriceSelect}
                        id="starting_price"
                        size={'small'}
                        fullWidth
                        labelId={'label-starting-price'}
                    >
                        <MenuItem value="market_price"> {playType === 'BULL' ? "Buy at" : "Sell at"} Market Price</MenuItem>
                        <MenuItem value="0.01"> {playType === 'BULL' ? "Buy at" : "Sell at"} 1% {playType === 'BULL' ? 'Below': "Above"} Market Price</MenuItem>
                        <MenuItem value="0.02"> {playType === 'BULL' ? "Buy at" : "Sell at"} 2% {playType === 'BULL' ? 'Below': "Above"} Market Price</MenuItem>
                        <MenuItem value="0.03"> {playType === 'BULL' ? "Buy at" : "Sell at"} 3% {playType === 'BULL' ? 'Below': "Above"} Market Price</MenuItem>
                        <MenuItem value="0.05"> {playType === 'BULL' ? "Buy at" : "Sell at"} 5% {playType === 'BULL' ? 'Below': "Above"} Market Price</MenuItem>
                        <MenuItem value="0.07"> {playType === 'BULL' ? "Buy at" : "Sell at"} 7% {playType === 'BULL' ? 'Below': "Above"} Market Price</MenuItem>
                        <MenuItem value="0.1"> {playType === 'BULL' ? "Buy at" : "Sell at"} 10% {playType === 'BULL' ? 'Below': "Above"} Market Price</MenuItem>
                    </Select>

                    <InputLabel id="label-ending-price">Ending Price</InputLabel>

                    <Select
                        color="primary"
                        value={endingPrice}

                        onChange={handleEndingPriceSelect}

                        id="ending_price"
                        size={'small'}
                        fullWidth
                        labelId={'label-ending-price'}

                    >

                        <MenuItem value="0.01">{playType === 'BULL' ? "Sell at" : "Buy at"} 1% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.02">{playType === 'BULL' ? "Sell at" : "Buy at"} 2% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.03">{playType === 'BULL' ? "Sell at" : "Buy at"} 3% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.05">{playType === 'BULL' ? "Sell at" : "Buy at"} 5% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.07">{playType === 'BULL' ? "Sell at" : "Buy at"} 7% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.1">{playType === 'BULL' ? "Sell at" : "Buy at"} 10% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.15">{playType === 'BULL' ? "Sell at" : "Buy at"} 15% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.20">{playType === 'BULL' ? "Sell at" : "Buy at"} 20% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>
                        <MenuItem value="0.25">{playType === 'BULL' ? "Sell at" : "Buy at"} 25% {playType === 'BULL' ? "Higher" : "Lower"}</MenuItem>

                    </Select>


            {funds.length > 0 && (
                        <>
                            <InputLabel id="label-funding">Funding</InputLabel>

                            <Grid aria-labelledby={"label-funding"} container spacing={2} >
                                <Grid item xs={6}>

                            <InputLabel id="label-fund-type">Fund Source</InputLabel>

                            <ToggleButtonGroup
                                color="primary"
                                value={existingOrNewFund}
                                exclusive
                                onChange={handleUseExistingOrNewFund}
                                id="fund-type"
                                aria-labelledby={'label-fund-type'}
                            >
                                <ToggleButton value="existing">Existing</ToggleButton>
                                <ToggleButton value="new">New</ToggleButton>
                            </ToggleButtonGroup>
                                </Grid>
                            <Grid item xs={6}>


                                {existingOrNewFund === 'new' && ( <> <InputLabel id="label-new-cash-fund">Amount in {marketApp === 'robinhood' ? 'USD' : 'MXN'}</InputLabel>


                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="amount_in_fiat_currency"
                                        aria-labelledby={'label-new-cash-fund'}
                                        id="amount_in_fiat_currency"
                                        type="number"
                                        inputProps={{ step: '0.01', lang: 'en-US' }}
                                        value={amountInUsd}
                                        onChange={
                                            (event) => {

                                                const { value } = event.target;
                                                setAmountInUsd(value);

                                            }}
                                    /> </>)}











                                {existingOrNewFund === 'existing' && (
                                    <>
                                        <InputLabel id="label-existing-cash-fund">Fund</InputLabel>
                                        <Select

                                            id="cash"
                                            name="cash"
                                            value={fundId}
                                            labelId={'label-existing-cash-fund'}
                                            onChange={handleChangeFundId}
                                            fullWidth
                                        >
                                            {funds.map(fund => {
                                                let label = ""
                                                if (fund.amount_in_usd) {
                                                    label = `$${fund.amount_in_usd.toFixed(2)} USD`
                                                }
                                                if(fund.amount_in_mxn) {
                                                    label = `$${fund.amount_in_mxn.toFixed(2)} MXN`

                                                }
                                                if (fund.amount_in_crypto) {
                                                    label = `${parseFloat(fund.amount_in_crypto).toFixed(6)} ${
                                                        fund.asset
                                                    }`
                                                }

                                                return (
                                                    <MenuItem key={fund.id} value={fund.id}>
                                                        {label}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </>
                                )}
                            </Grid>


                            </Grid>
                        </>

                    )}

                    {funds.length === 0 && (
                        <>
                            <InputLabel id="label-new-cash-fund">Amount in {marketApp === 'robinhood' ? 'USD' : 'MXN'}</InputLabel>


                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="amount_in_fiat_currency"
                                aria-labelledby={'label-new-cash-fund'}
                                id="amount_in_fiat_currency"
                                type="number"
                                inputProps={{ step: '0.01', lang: 'en-US' }}
                                value={amountInUsd}
                                onChange={
                                    (event) => {

                                        const { value } = event.target;
                                        setAmountInUsd(value);

                                    }}
                            />

                        </>)
                    }









            {minBetSize > betSize && betSize !== 0.0 &&
                        <Alert severity="error">
                            {  marketApp === 'robinhood' && <strong>Min fund amount is {minBetSize.toFixed(2)} USD or Min Play Gains {minPlayGains.toFixed(2)}%</strong> }
                            { marketApp === 'bitso' && <strong>Min fund amount is {minBetSize.toFixed(0)} MXN or Min Play Gains {minPlayGains.toFixed(2)}%</strong> }
                            </Alert>}


            {startingTransactionInfo !== '' && (minBetSize < betSize || betSize === 0.0)  &&
                <>
                    <InputLabel id="label-play-strategy">Strategy</InputLabel>

                    <Grid aria-labelledby={"label-play-strategy"} container spacing={2} >
                    <Grid item xs={6}>
                        <Chip color={playType === 'BULL' ? 'success' : 'error'} label={startingTransactionInfo} variant="outlined" />
                        {/*<Box><Typography variant="button">{startingTransactionInfo}</Typography></Box>*/}
                    </Grid>


                    <Grid item xs={6}>
                        <Chip color={playType === 'BULL' ? 'error' : 'success'}  label={endingTransactionInfo} variant="outlined" />
                        {/*<Box><Typography variant="button">{endingTransactionInfo}</Typography></Box>*/}
                    </Grid>


                </Grid>
                </>

            }
            <Grid container spacing={8} >
                <Grid item xs={4}>
                    <Rating
                        icon={<MonetizationOnIcon fontSize="inherit" />}
                        emptyIcon={<AttachMoneyIcon fontSize="inherit" />}

                        name="half-rating-read" value={riskMetric} precision={0.5} readOnly />
                    <Box><Typography variant="subtitle2"><strong>Risk</strong></Typography></Box>
                </Grid>
                {rewardMetric !== 0.0 &&
                    <>
                    < Grid item xs={4}>

                        <Box><PercentIcon/> <Typography variant="subtitle2"><strong>{playGains.toFixed(2)}</strong></Typography></Box>
                    </Grid>
                        < Grid item xs={4}>

                            <Box><PaidIcon  /><Typography variant="subtitle2"><strong>{rewardMetric.toFixed(2)}</strong> {marketApp === 'robinhood' ? 'USD' : 'MXN'}</Typography></Box>
                        </Grid>
                    </>
                }
            </Grid>

            <Grid container spacing={2} >

                <Grid item xs={6}>

            <Button
                color="secondary"

                fullWidth

                onClick={()=> navigate('/dashboard')}
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
            >
                Cancel
            </Button>
                </Grid>
                <Grid item xs={6}>


                    <Button
                        fullWidth
                        disabled={isLoading || minBetSize > betSize || betSize == 0.0 }
                        startIcon={isLoading && <CircularProgress color={'secondary'} />}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Make Play
                    </Button>
                </Grid>
            </Grid>
        </Container><AppBarWithDrawer showFundAndPlayButtons={false} />

        </>
    )
}
