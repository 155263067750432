import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { cryptoCopilotLocalStorageKey } from '../App'
import {CryptoInvestSettings, ExchangePrices, MarketApps} from "./Dashboard";


// Define a type for the slice state
interface DashboardState {   
    profile: Profile
    isDarkMode: boolean,
    exchangePrices: ExchangePrices
    cryptoInvestSettings: CryptoInvestSettings
    marketApps: MarketApps
}

  export interface Profile {
    // imageUrl: string
    // name: string
    // email: string
    accessToken: string
    accessTokenExpiresAt: number | null
  }
  
  // Define the initial state using that type
  const initialState: DashboardState = {
    profile: {
      // name: '',
      // imageUrl: '',
      // email: '',
      accessToken: '',
      accessTokenExpiresAt: null
    },
    isDarkMode: false,
    exchangePrices: {
      ETH: 0.0, BTC: 0.0, DOGE:0.0, AVAX: 0.0, ETC: 0.0,  XLM:0.0,BCH:0.0, LINK:0.0, UNI:0.0
    },
    cryptoInvestSettings: {
      ETH: 'crypto',
      BTC: 'crypto',
      DOGE: 'crypto',
      AVAX: 'crypto',
      XLM: 'crypto',
      BCH: 'crypto',
      LINK: 'crypto',
      UNI: 'crypto',
      ETC: 'crypto'
    },
    marketApps: { apps: []}
  }

  export const dashboardSlice = createSlice({
    name: 'counter',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
      reset: (state) => {
        state.profile.accessToken = ''
        state.profile.accessTokenExpiresAt = null
        localStorage.removeItem(cryptoCopilotLocalStorageKey)
        state.exchangePrices = {
          ETH: 0.0, BTC: 0.0, DOGE:0.0, AVAX:0.0, ETC: 0.0, XLM:0.0, BCH:0.0, LINK:0.0, UNI:0.0
        }
      },
      // TODO: some stupid typescript bullshit don't feel like fixing since were not using it yet
      // setKeyValueOfProfile:(state, action: PayloadAction<{key: keyof Profile, value: any}>) => {
      //   state.profile[action.payload.key] = action.payload.value
      // }
      // ,
      setProfile: (state, action: PayloadAction<Profile>) => {
        state.profile = action.payload
      },
      setDarkMode: (state, action: PayloadAction<boolean>) => {
        state.isDarkMode = action.payload
      },
      setExchangePrices: (state, action) => {
        state.exchangePrices = action.payload
      },
      setCryptoInvestSettings:(state, action: PayloadAction<CryptoInvestSettings>) => {
        state.cryptoInvestSettings = action.payload
      }
      ,
      setMarketApps:(state, action: PayloadAction<MarketApps>) => {
        state.marketApps = action.payload
      }
    },
  })


export const { reset, setMarketApps, setProfile, setDarkMode, setExchangePrices, setCryptoInvestSettings} = dashboardSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectAccessToken = (state: RootState) => state.dashboard.profile.accessToken

export default dashboardSlice.reducer
