import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../hooks'
import { Asset, dashboardAPI } from './DashboardAPI'
import {
  DataGrid,
  GridCellValue,
  GridColumns,
  GridRowsProp
} from '@mui/x-data-grid'
import { AppBarWithDrawer } from '../shared/AppBarWithDrawer'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import EditPlayModal from '../shared/EditPlayModal'


import EditManyPlaysModal from "../shared/EditManyPlaysModal";
import {useNavigate} from "react-router-dom";
import {isTokenExpired} from "../utils";
import {reset} from "./dashboardSlice";
import {cryptoCopilotLocalStorageKey} from "../App";
import {useDispatch} from "react-redux";

export interface PlayDashboardResponse {
  open_plays: PlayEntity[]
}

export interface PlayDetailStatus  {
  ending_transaction_has_been_fulfilled?: string
  starting_transaction_has_been_fulfilled?: string
  ending_transaction_submitted?: string
  starting_transaction_submitted?: string
}
export interface PlayEntity {
  amount_in_usd: number
  amount_in_mxn: string
  create_ts: string
  crypto_symbol: string
  ending_asset: string
  ending_price: number
  ending_transaction: string
  ending_quantity: string
  funding_id: string
  id: string
  price: string
  quantity: string
  starting_order_has_been_fulfilled: boolean
  starting_transaction: string
  starting_transaction_type: string
  status: string
  updated_price_on_order_fulfilment: boolean
  user: string
  last_upd_ts: string
  gains: number
  gains_asset: string
  gains_string: string
  gains_in_usd: number
  play_source_label: string
  market_app: string
  detailed_status?: PlayDetailStatus
  refresh(): void
}

export default function Plays() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { profile } = useAppSelector(state => state.dashboard)
  const { exchangePrices } = useAppSelector(state => state.dashboard)

  const [plays, setPlays] = useState<{
    bear: GridRowsProp<PlayEntity>
    bull: GridRowsProp<PlayEntity>
  }>({ bear: [], bull: [] })

  const [playMap, setPlayMap] = useState<Map<GridCellValue, PlayEntity>>(
    new Map<GridCellValue, PlayEntity>()
  )
  // TODO: set default based on what the user has
  const [asset, setAsset] = React.useState<Asset>('ETH')
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)

  const plsRefresh = () => {
    setRefresh(!refresh)
  }


  const handleChange = (event: SelectChangeEvent) => {
    setAsset(event.target.value as Asset)
  }
  const handleSignOut = () => {
    dispatch(reset())
    localStorage.removeItem(cryptoCopilotLocalStorageKey)
    navigate('/')
  }
  useEffect(() => {
    async function getPlays() {
      setLoading(true)
      if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
        handleSignOut()
      }
      const res = await dashboardAPI.getPlays(
        // profile.email,
        asset,
        profile.accessToken
      )

      const fundsData: PlayDashboardResponse =
        res === undefined ? { open_plays: [], open_funds: [] } : res.data
      //const fundsData: PlayEntity[] = res === undefined ? [] : res.data
      console.log('getPlays res', fundsData)
      if (fundsData !== undefined && fundsData.open_plays.length > 0) {
        const bear = fundsData.open_plays.filter(
          f => f.status === 'open' && f.starting_transaction_type === 'sell'
        )
        const bull = fundsData.open_plays.filter(
          f => f.status === 'open' && f.starting_transaction_type === 'buy'
        )
        const newPlayMap = new Map()
        fundsData.open_plays.forEach(x => {
          x.refresh = plsRefresh
          newPlayMap.set(x.id, x)
        })
        setPlays({ bull, bear })
        setPlayMap(newPlayMap)




      }

      setLoading(false)
    }
    getPlays()
  }, [asset, profile.accessToken, refresh])

  const getBullColumns: GridColumns = [
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'id',
      headerName: 'Play Id',
      type: 'string'
    },
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'price',
      headerName: 'BUY',
      type: 'string'
    },
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'ending_price',
      headerName: 'SELL',
      type: 'number'
    },
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'gains_in_usd',
      headerName: 'Potential Gains',
      type: 'number'
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const play: PlayEntity | undefined = playMap.get(params.row['id'])
        let you_playing: PlayEntity = new (class implements PlayEntity {
          amount_in_usd: number = 0.0
          amount_in_mxn: string = "0.0"
          create_ts: string = ''
          crypto_symbol: string = ''
          ending_asset: string = ''
          ending_price: number = 0.0
          ending_quantity: string = ''
          ending_transaction: string = ''
          funding_id: string = ''
          id: string = ''
          last_upd_ts: string = ''
          price: string = ''
          quantity: string = ''
          starting_order_has_been_fulfilled: boolean = false
          starting_transaction: string = ''
          starting_transaction_type: string = ''
          status: string = ''
          updated_price_on_order_fulfilment: boolean = false
          user: string = ''
          gains: number = 0.0
          gains_string: string = ''
          gains_asset: string = ''
          gains_in_usd: number = 0.0
          play_source_label: string = ''
          market_app: string = ''

          refresh(): void {
            plsRefresh()
          }
        })()
        if (play !== undefined) {
          you_playing = play
        }
        return <EditPlayModal {...you_playing} />
      }
    }
  ]

  const getBearColumns: GridColumns = [
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'id',
      headerName: 'Play Id',
      type: 'string'
    },
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'price',
      headerName: 'SELL',
      type: 'string'
    },
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'ending_price',
      headerName: 'BUY',
      type: 'number'
    },
    {
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      field: 'gains_in_usd',
      headerName: 'Potential Gains',
      type: 'number'
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const play: PlayEntity | undefined = playMap.get(params.row['id'])
        let you_playing: PlayEntity = new (class implements PlayEntity {
          amount_in_usd: number = 0.0
          amount_in_mxn: string = "0.0"
          create_ts: string = ''
          crypto_symbol: string = ''
          ending_asset: string = ''
          ending_price: number = 0.0
          ending_quantity: string = ''
          ending_transaction: string = ''
          funding_id: string = ''
          id: string = ''
          last_upd_ts: string = ''
          price: string = ''
          quantity: string = ''
          starting_order_has_been_fulfilled: boolean = false
          starting_transaction: string = ''
          starting_transaction_type: string = ''
          status: string = ''
          updated_price_on_order_fulfilment: boolean = false
          user: string = ''
          gains: number = 0.0
          gains_string: string = ''
          gains_asset: string = ''
          gains_in_usd: number = 0.0
          play_source_label: string = ''
          market_app: string = ''

          refresh(): void {
            plsRefresh()
          }
        })()
        if (play !== undefined) {
          you_playing = play
        }
        return <EditPlayModal {...you_playing} />
      }
    }
  ]

  return (
    <Container sx={{ marginTop: '65px' }} maxWidth="xl">
      <AppBarWithDrawer showFundAndPlayButtons={false} />
      {!loading && (
        <>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <EditManyPlaysModal refresh={plsRefresh} ></EditManyPlaysModal>
            </Grid>
            <Grid item xs={12}>

              <Typography gutterBottom variant="h4">
                Filter By Asset
              </Typography>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="asset-filter-select">Asset</InputLabel>
                  <Select
                    labelId="asset-filter-select"
                    id="asset-filter-select"
                    value={asset}
                    label="Current Asset"
                    onChange={handleChange}
                  >
                    <MenuItem value={'ETH'}>ETH</MenuItem>
                    <MenuItem value={'BTC'}>BTC</MenuItem>
                    <MenuItem value={'DOGE'}>DOGE</MenuItem>
                    <MenuItem value={'ETC'}>ETC</MenuItem>
                    <MenuItem value={'AVAX'}>AVAX</MenuItem>
                    <MenuItem value={'XLM'}>XLM</MenuItem>
                    <MenuItem value={'BCH'}>BCH</MenuItem>
                    <MenuItem value={'LINK'}>LINK</MenuItem>
                    <MenuItem value={'UNI'}>UNI</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Button onClick={() => navigate('/dashboard/make_play')}>Make Play</Button>
            </Grid>

            <Grid item md={6} xs={12}>
              <Typography
                sx={{ color: 'red' }}
                gutterBottom
                variant="h3"
                display={'block'}
              >
                Bear Plays
              </Typography>
              <div style={{ height: 800, width: '100%' }}>
                <DataGrid
                  rows={plays.bear}
                  columns={getBearColumns}
                  experimentalFeatures={{ newEditingApi: true }}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false
                      }
                    },
                    sorting: {
                      sortModel: [{ field: 'ending_price', sort: 'desc' }]
                    }
                  }}
                />
              </div>
            </Grid>
            <Divider />
            <Grid item md={6} xs={12}>
              <Typography
                sx={{ color: 'green' }}
                gutterBottom
                variant="h3"
                display={'block'}
              >
                Bull Plays
              </Typography>
              <div style={{ height: 800, width: '100%' }}>
                <DataGrid
                  rows={plays.bull}
                  columns={getBullColumns}
                  experimentalFeatures={{ newEditingApi: true }}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false
                      }
                    },
                    sorting: {
                      sortModel: [{ field: 'ending_price', sort: 'asc' }]
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>{' '}
        </>
      )}
      {loading && <CircularProgress color={'secondary'} />}
    </Container>
  )
}
