import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Container, TextField, Button, Box
} from '@mui/material'
import React from 'react'
import { CryptoInvestSettings, DashboardStatusEntity } from './Dashboard'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {BitsoProfile, dashboardAPI, RobinHoodProfile} from './DashboardAPI'
import { useAppSelector } from '../hooks'
import { AppBarWithDrawer } from '../shared/AppBarWithDrawer'
import { useDispatch } from 'react-redux'
import { setCryptoInvestSettings } from './dashboardSlice'

import CheckBoxIcon from '@mui/icons-material/CheckBox';

type Props = {}

export default function Settings({}: Props) {
  const { profile, cryptoInvestSettings, marketApps } = useAppSelector(
    (state) => state.dashboard
  )
  const dispatch = useDispatch()
  const [bet_settting, setBet_setting] = React.useState('BOTH')

  const refetchData = () => {
    // refetch data
    dashboardAPI
      .checkUser(profile.accessToken)
      .then((res) => {
        let dashboardResp: DashboardStatusEntity | null
        if (res !== undefined && res.data.robinhood_email !== '') {
          dashboardResp = res === undefined ? undefined : res.data

          if (
            dashboardResp !== undefined &&
            dashboardResp !== null &&
            dashboardResp.robinhood_email !== ''
          ) {
            dispatch(
              setCryptoInvestSettings(dashboardResp.crypto_invest_settings)
            )
            setBet_setting(dashboardResp.bet_setting)
          }
        }
      })
      .catch((e) => console.error('error', e))
  }

  const handleChangeInvestModeForCoin = (
    event: SelectChangeEvent,
    coin: string
  ) => {
    console.log(' handleChangeInvestModeForCoin event', event.target.value)
    console.log('coin', coin)
    dashboardAPI
      .postCryptoInvestSettings(
        profile.accessToken,
        coin,
        event.target.value
      )
      .then((x) => console.log('success', x))
      .catch((e) => console.error('error', e))

    // refetch data
    refetchData()
  }

  const handleChangeInvestMode = (event: SelectChangeEvent) => {
    dashboardAPI
      .postBetSetting( profile.accessToken, event.target.value)
      .then((x) => refetchData())
  }

  const handleRobinhoodSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    console.log('in the onsubmit')
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const value: RobinHoodProfile = {
      robinhood_email: data.get('robinhood_email'),
      robinhood_pw: data.get('robinhood_pw'),
      robinhood_token: data.get('robinhood_token'),
      market_app: 'robinhood'
    }
    dashboardAPI
        .postUser(String( profile.accessToken), value)
        .then((x) => console.log("heyyy"))
  }


  const handleBitsoSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    console.log('in the onsubmit')
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const value: BitsoProfile = {
      bitso_email: data.get('bitso_email'),
      bitso_api_key: data.get('bitso_api_key'),
      bitso_api_secret: data.get('bitso_api_secret'),
      market_app: 'bitso'
    }
    dashboardAPI
        .postUser(String( profile.accessToken), value)
        .then((x) => console.log("heyyy"))
  }


  return (
    <Container>

      {marketApps.apps.length === 0 && ( <>
        <Typography variant="h5" component="h5">
          Welcome to CryptoCopilot
        </Typography>

        <Typography variant="h5" component="h5">
        Missing Crypto Market Application Configuration
      </Typography>
        <p>
          Choose between Robinhood or Bitso
        </p>
      </>)}
      <Grid item xs={12}>
        {marketApps.apps.length > 0 &&
            (<Accordion sx={{borderRadius: '25px !important'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                {/* Tbh idk what to put here, maybe like notifications on recent plays. Number of open funds, number of open plays???? */}
                <Typography variant="h5" component="h5">
                  Crypto Invest Settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl fullWidth>
                  <h3>Play Type Settings</h3>
                  <Select
                      labelId="demo-simple-select-label2"
                      id="demo-simple-select2"
                      value={bet_settting}
                      onChange={handleChangeInvestMode}
                  >
                    <MenuItem value={'NONE'}>Do not make plays</MenuItem>
                    <MenuItem value={'BOTH'}>
                      Make both BULL and BEAR plays
                    </MenuItem>
                    <MenuItem value={'BULL'}>Make only BULL plays</MenuItem>
                    <MenuItem value={'BEAR'}>Make only BEAR plays</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <h3>BTC Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={cryptoInvestSettings.BTC}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'BTC')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <h3>ETH Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-eth"
                      id="demo-simple-select-eth"
                      value={cryptoInvestSettings.ETH}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'ETH')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <h3>DOGE Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-doge"
                      id="demo-simple-select-doge"
                      value={cryptoInvestSettings.DOGE}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'DOGE')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <h3>AVAX Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-avax"
                      id="demo-simple-select-avax"
                      value={cryptoInvestSettings.AVAX}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'AVAX')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <h3>XLM Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-xlm"
                      id="demo-simple-select-xlm"
                      value={cryptoInvestSettings.XLM}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'XLM')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <h3>BCH Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-bch"
                      id="demo-simple-select-bch"
                      value={cryptoInvestSettings.BCH}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'BCH')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <h3>LINK Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-link"
                      id="demo-simple-select-link"
                      value={cryptoInvestSettings.LINK}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'LINK')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <h3>UNI Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-uni"
                      id="demo-simple-select-uni"
                      value={cryptoInvestSettings.UNI}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'UNI')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <h3>ETC Invest Mode</h3>
                  <Select
                      labelId="demo-simple-select-label-etc"
                      id="demo-simple-select-etc"
                      value={cryptoInvestSettings.ETC}
                      onChange={(e) => handleChangeInvestModeForCoin(e, 'ETC')}
                  >
                    <MenuItem value={'dont_invest'}>
                      Do not invest on this coin
                    </MenuItem>
                    <MenuItem value={'usd'}>
                      Invest with purpose to gain USD
                    </MenuItem>
                    <MenuItem value={'crypto'}>
                      Invest with purpose to gain crypto
                    </MenuItem>
                    <MenuItem value={'both'}>
                      Invest with purpose to gain both USD and crypto
                    </MenuItem>
                  </Select>
                </FormControl>
              </AccordionDetails>
            </Accordion>)
        }

        {!marketApps.apps.includes('robinhood') ?
            <Accordion sx={{borderRadius: '25px !important'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                {/* Tbh idk what to put here, maybe like notifications on recent plays. Number of open funds, number of open plays???? */}
                <Typography variant="h5" component="h5">
                  Robinhood (USA)
                </Typography>
              </AccordionSummary>
              <AccordionDetails>

                <Box
                    component="form"
                    noValidate
                    onSubmit={handleRobinhoodSubmit}
                    sx={{mt: 1}}
                >
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="robinhood_email"
                      label="Email Address"
                      name="robinhood_email"
                      autoComplete="email"
                      autoFocus
                  />
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="robinhood_pw"
                      label="Password"
                      type="password"
                      id="robinhood_pw"
                      autoComplete="current-password"
                  />
                  <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="robinhood_token"
                      label="robinhood_token"
                      type="password"
                      id="robinhood_token"
                  />
                  <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{mt: 3, mb: 2}}
                  >
                    Sign In
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            : <>
              <br/>
              <Typography variant={"h3"}>Robinhood<CheckBoxIcon/></Typography>
              <br/>
            </>
        }
        { !marketApps.apps.includes('bitso') ?
        <Accordion sx={{ borderRadius: '25px !important' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {/* Tbh idk what to put here, maybe like notifications on recent plays. Number of open funds, number of open plays???? */}
            <Typography variant="h5" component="h5">
              Bitso (MEXICO)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
                component="form"
                noValidate
                 onSubmit={handleBitsoSubmit}
                sx={{ mt: 1 }}
            >
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="bitso_email"
                  label="Email Address"
                  name="bitso_email"
                  autoComplete="email"
                  autoFocus
              />
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="bitso_api_key"
                  label="Bitso API Key"
                  type="password"
                  id="bitso_api_key"
                  autoComplete="current-password"
              />
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="bitso_api_secret"
                  label="Bitso API Secret"
                  type="password"
                  id="bitso_api_secret"
              />
              <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion> :
          <>
            <br/>
          <Typography variant={"h3"}>Bitso<CheckBoxIcon/></Typography>
          </>
        }

      </Grid>
      <AppBarWithDrawer showFundAndPlayButtons={false} />
    </Container>
  )
}
