import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Add'
import {
    CircularProgress,
    Fab,
    MenuItem,
    TextField,
    Typography
} from '@mui/material'
import { Asset, dashboardAPI } from '../dashboard/DashboardAPI'
import { useAppSelector } from '../hooks'
import { FundEntity } from '../dashboard/Funds'
import {PlayEntity} from "../dashboard/Plays";
import {isTokenExpired} from "../utils";
import {reset} from "../dashboard/dashboardSlice";
import {cryptoCopilotLocalStorageKey} from "../App";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
}

export interface EditPlay {
    id: string
    new_ending_price: null | FormDataEntryValue
    change_exit_price: boolean
}

export interface EditManyPlays {
    play: null | FormDataEntryValue
    new_ending_price: null | FormDataEntryValue
    crypto_symbol: null | FormDataEntryValue
}


function BearMenuItem({ amount_in_crypto, id, amount_in_usd }: FundEntity) {
    if (
        amount_in_crypto === undefined ||
        amount_in_crypto === null ||
        amount_in_crypto === ''
    ) {
        return (
            <MenuItem key={id} value={id}>
                {amount_in_usd}
            </MenuItem>
        )
    }
    return (
        <MenuItem key={id} value={id}>
            {amount_in_crypto}
        </MenuItem>
    )
}
BearMenuItem.defaultProps = {
    className: 'menu-item'
}

export default function EditPlayModal({
                                          amount_in_usd,
                                          create_ts,
                                          crypto_symbol,
                                          ending_asset,
                                          ending_price,
                                          ending_transaction,
                                          ending_quantity,
                                          funding_id,
                                          id,
                                          price,
                                          quantity,
                                          starting_order_has_been_fulfilled,
                                          starting_transaction,
                                          starting_transaction_type,
                                          status,
                                          updated_price_on_order_fulfilment,
                                          user,
                                          last_upd_ts,
                                          refresh, gains_string, play_source_label, market_app
}: PlayEntity) {

    const calculateNewBullPlayGains = (new_ending_price: string) => {
        let gains2 = amount_in_usd/ parseFloat(price) - amount_in_usd/parseFloat( new_ending_price);
        let gains_in_usd = 0.0;
        if (crypto_symbol === 'ETH' && exchangePrices !== undefined && exchangePrices.ETH !== undefined) {
            gains_in_usd = gains2 * exchangePrices.ETH;
        }
        if (crypto_symbol === 'DOGE' && exchangePrices !== undefined && exchangePrices.DOGE !== undefined) {
            gains_in_usd = gains2 * exchangePrices.DOGE;
        }
        if (crypto_symbol === 'BTC' && exchangePrices !== undefined && exchangePrices.BTC !== undefined) {
            gains_in_usd = gains2 * exchangePrices.BTC;
        }
        if (crypto_symbol === 'AVAX' && exchangePrices !== undefined && exchangePrices.AVAX !== undefined) {
            gains_in_usd = gains2 * exchangePrices.AVAX;
        }


        if (crypto_symbol === 'XLM' && exchangePrices !== undefined && exchangePrices.XLM !== undefined) {
            gains_in_usd = gains2 * exchangePrices.XLM;
        }

        if (crypto_symbol === 'BCH' && exchangePrices !== undefined && exchangePrices.BCH !== undefined) {
            gains_in_usd = gains2 * exchangePrices.BCH;
        }

        if (crypto_symbol === 'LINK' && exchangePrices !== undefined && exchangePrices.LINK !== undefined) {
            gains_in_usd = gains2 * exchangePrices.LINK;
        }

        if (crypto_symbol === 'UNI' && exchangePrices !== undefined && exchangePrices.UNI !== undefined) {
            gains_in_usd = gains2 * exchangePrices.UNI;
        }



        if (crypto_symbol === 'ETC' && exchangePrices !== undefined && exchangePrices.ETC !== undefined) {
            gains_in_usd = gains2 * exchangePrices.ETC;
        }
        return `${gains2.toFixed(6)} ${crypto_symbol} ~ $${gains_in_usd.toFixed(2)} USD`
    }


    const calculateNewBearPlayGains = (new_ending_price: string) => {
        let gains2 =
            parseFloat(price) * parseFloat(quantity) -
            parseFloat(new_ending_price) * parseFloat(quantity)
        return `$${gains2.toFixed(2)} USD`
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [open, setOpen] = React.useState<boolean>(false)

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [newExitPrice, setNewExitPrice] = React.useState<string>(ending_price.toString())
    const [nextGains, setGains] = React.useState<string>(starting_transaction_type === 'sell' ? calculateNewBearPlayGains(ending_price.toString()) : calculateNewBullPlayGains(ending_price.toString()))
    const [formValid, setFormValid] = React.useState<boolean>(true);



    const { profile } = useAppSelector(state => state.dashboard)

    const { exchangePrices } = useAppSelector(state => state.dashboard)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSignOut = () => {
        dispatch(reset())
        localStorage.removeItem(cryptoCopilotLocalStorageKey)
        navigate('/')
    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true)
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const value: EditPlay = {
            id: id,
            new_ending_price: data.get('new_ending_price'),
            change_exit_price: true,
        }
         console.log('this is the data');
         console.log(data.get('new_ending_price'));
         console.log('this is the data');

        if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
            handleSignOut()
        }

        dashboardAPI.editPlay(profile.accessToken, value).then(x => {
            setIsLoading(false)
            handleClose()
            navigate('/dashboard/play/' + id)
        })
    }
    let play_type = "Bull"

    if(starting_transaction_type === 'sell') {
        play_type = "Bear"
    }

    return (
        <div>
            <Button variant={'contained'}
                    onClick={handleOpen}
                    color={'secondary'}>
            EDIT <EditIcon />

            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box
                    sx={{ ...style, width: 400 }}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Typography sx={{ marginLeft: '16px' }} variant="h3">
                        Edit Play
                    </Typography>
                    <p>ID: {id}</p>

                    <p>Type of Play: {play_type}</p>
                    <p>Starting price: {price}</p>
                    <p>Exit price: {ending_price}</p>
                    <p>Current Potential Gains: {gains_string}</p>
                    <p>{play_source_label}</p>

                    <p>{market_app}</p>

                    <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="new_ending_price"
                                label="New Exit Price"
                                id="new_ending_price"
                                type="number"
                                defaultValue={ending_price}
                                value={newExitPrice}
                                inputProps={{ step: '0.01', lang: 'en-US' }}
                                onChange={ e => {setNewExitPrice(e.target.value); setFormValid( !( e.target.value === "" || (starting_transaction_type === 'buy' && parseFloat(e.target.value) < parseFloat(price) ) ||
                                    (starting_transaction_type === 'sell' && parseFloat(e.target.value) > parseFloat(price) ))); setGains(starting_transaction_type === 'sell' ? calculateNewBearPlayGains(e.target.value) : calculateNewBullPlayGains(e.target.value))
                                }}
                                error={ newExitPrice === "" || (starting_transaction_type === 'buy' && parseFloat(newExitPrice) < parseFloat(price) ) ||
                                    (starting_transaction_type === 'sell' && parseFloat(newExitPrice) > parseFloat(price) )}

                                helperText={starting_transaction_type === 'buy' && parseFloat(newExitPrice) < parseFloat(price) ? 'For Bull Plays, exit price must be higher than entry price' :
                                    starting_transaction_type === 'sell' && parseFloat(newExitPrice) > parseFloat(price) ? 'For Bear Plays, exit price must be higher than entry price' : ' '}

                            />

                    <p>New Potential Gains: {nextGains}</p>


                    <Button
                        fullWidth
                        disabled={isLoading || !formValid}
                        startIcon={isLoading && <CircularProgress color={'secondary'} />}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Edit Play
                    </Button>
                </Box>
            </Modal>
        </div>
    )
}
