import {
  ChevronLeft,
  ChevronRight,
  Logout,
  MoneyRounded,
  PlayArrowRounded,
  Menu,
  Dashboard,
  PlayArrow,
  PlayCircle,
  Settings, Money
} from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home';
import {
  Box,
  IconButton,
  Typography,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  styled,
  FormControlLabel,
  FormGroup,
  Switch,
  Avatar,
  Toolbar
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useGoogleLogout } from 'react-google-login'
import {useLocation, useNavigate} from 'react-router-dom'
import { clientId, cryptoCopilotLocalStorageKey } from '../App'
import { reset, setDarkMode } from '../dashboard/dashboardSlice'
import { useAppDispatch, useAppSelector } from '../hooks'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { dashboardAPI } from '../dashboard/DashboardAPI'
import Fab from '@mui/material/Fab'
import Paper from '@mui/material/Paper'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListSubheader from '@mui/material/ListSubheader'
import MenuIcon from '@mui/icons-material/Menu'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import MoreIcon from '@mui/icons-material/MoreVert'

const drawerWidth = 240

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto'
})

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center'
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  })
}))

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2
  }
}))

interface Props {
  toggleAddFundModal?: () => void
  showFundAndPlayButtons?: boolean
}

export const AppBarWithDrawer = ({
  toggleAddFundModal, showFundAndPlayButtons
}: Props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const { profile, isDarkMode, marketApps } = useAppSelector((state) => state.dashboard)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setDrawerOpen(open)
  }

  const handleSignOut = () => {
    dispatch(reset())
    localStorage.removeItem(cryptoCopilotLocalStorageKey)
    navigate('/')
  }

  const drawerNavItems = [
    {
      label: 'Dashboard',
      icon: <Dashboard />,
      route: '/dashboard'
    },
    {
      label: 'Funds',
      icon: <MoneyRounded />,
      route: '/dashboard/funds'
    },
    {
      label: 'Plays',
      icon: <PlayArrowRounded />,
      route: '/dashboard/plays'
    },
    {
      label: 'Settings',
      icon: <Settings />,
      route: '/dashboard/settings'
    }
    ,
    {
      label: 'Make Play',
      icon: <Money />,
      route: '/dashboard/make_play'
    }
  ]

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        {drawerNavItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            {/* TODO: user local storage or redux to check for existing user */}
            <ListItemButton
              //   disabled={!existinguser}
              onClick={() => {
                toggleDrawer(false)
                navigate(item.route)
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem key={'signout'} disablePadding>
          <ListItemButton
            onClick={() => {
              handleSignOut();
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItemButton>
        </ListItem>
        <ListItem key={'theme-toggle'}>
          <FormGroup>
            <FormControlLabel
              control={
                <MaterialUISwitch
                  sx={{ m: 1 }}
                  checked={isDarkMode}
                  onChange={(e) => dispatch(setDarkMode(e.target.checked))}
                />
              }
              label="Mode"
            />
          </FormGroup>
        </ListItem>
      </List>
    </Box>
  )
  return (
    <>
      {marketApps.apps.length > 0 && (<> <AppBar
        sx={{ top: 'auto', bottom: 0 }}
        position="fixed"
        open={drawerOpen}
      >
        <Toolbar>
          {location.pathname === '/dashboard' ?
          <IconButton
            onClick={toggleDrawer(!drawerOpen)}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
              :
              <IconButton
                  onClick={() => {
                    navigate('/dashboard')
                  }}
                  color="inherit"
              >
                <HomeIcon />
              </IconButton> }
          {showFundAndPlayButtons && <>
          <StyledFab
            onClick={() => {
              if (toggleAddFundModal) {
                toggleAddFundModal()
              }
            }}
            color="primary"
            aria-label="add fund"
          >
            <AddIcon />
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />
           <IconButton
            onClick={() => {
              navigate('/dashboard/make_play')
            }}
            color="inherit"
          >
            <PlayCircle />
          </IconButton>
        </>}
        </Toolbar>
      </AppBar>
      <React.Fragment key={'left'}>
        <SwipeableDrawer
          anchor={'left'}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          variant="persistent"
        >
          <DrawerHeader>
            {/*<Avatar alt={profile.name} src={profile.imageUrl} />*/}
          </DrawerHeader>
          <Typography gutterBottom display={'block'}>
            {/*{profile.name}*/}
          </Typography>
          <Divider />
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </>)}
      </>
  )
}
