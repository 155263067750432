import { createTheme } from '@mui/material'

export const getTheme = (isDarkMode: boolean) =>
  createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#5bc04f'
      },
      secondary: {
        main: '#898d90'
      }
    }
  })
