import {
  Grid,
  TextField,
  Button,
  Avatar,
  Box,
  Paper,
  Typography,
  Container,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Fab,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import robinhoodLogo from '../assets/robinhood-logo.png'
import { useAppDispatch, useAppSelector } from '../hooks'
import PlayListItem from '../shared/PlayListItem'
import {
  dashboardAPI,
  RobinHoodProfile
} from './DashboardAPI'
import { PlayEntity } from './Plays'
import AddFundModal from '../shared/AddFundModal'

import { AppBarWithDrawer } from '../shared/AppBarWithDrawer'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { TotalGainsCard } from '../shared/TotalGainsCard'
import LoadingSkeletonCard from '../shared/LoadingSkeleton'
import EditManyPlaysModal from '../shared/EditManyPlaysModal'
import {reset} from './dashboardSlice'
import {useNavigate} from "react-router-dom";
import {isTokenExpired} from "../utils";
import {cryptoCopilotLocalStorageKey} from "../App";


function Dashboard() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { profile, exchangePrices } = useAppSelector((state) => state.dashboard)

  const [existinguser, setExistingUser] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(true)

  const setToLoading = () => {
    setLoading(true)
  }

  const plsRefresh = () => {
    setRefresh(!refresh)
  }
  const [totalGains, setTotalGains] = useState<TotalGains>({
    cash: '',
    ETH: '',
    BTC: '',
    DOGE: '',
    AVAX: '',
    XLM: '',
    BCH: '',
    LINK: '',
    UNI: '',
    ETC: ''
  })

  const [totalGainsEver, setTotalGainsEver] = useState<string>('')
  const [recentlyClosedPlays, setRecentlyClosedPlays] = useState<PlayEntity[]>(
    []
  )
  const [recentlyOpenedPlays, setRecentlyOpenedPlays] = useState<PlayEntity[]>(
    []
  )

  const [openAddFundModel, setOpenAddFundModel] = React.useState<boolean>(false)
  const [openMakePlayModel, setOpenMakePlayModel] = React.useState<boolean>(
    false
  )
  const handleSignOut = () => {
    dispatch(reset())
    localStorage.removeItem(cryptoCopilotLocalStorageKey)
    navigate('/')
  }

  useEffect(() => {
    console.log('accessToken form redux', profile.accessToken)
    async function run() {
      setLoading(true)
      console.log('HEyyy in here HHHHHHHH ' + String(profile.accessToken))
      if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
        handleSignOut()
      }

      const res = await dashboardAPI.checkUser(
        String(profile.accessToken)
      )
      console.log('checkUser res', res === undefined ? '' : res.data)
      let dashboardResp: DashboardStatusEntity | null
      if (res !== undefined && res.data.market_apps.apps.length !== 0) {
        console.log('setting as user does exists')
        setExistingUser(true)
        dashboardResp = res === undefined ? undefined : res.data
        setLoading(false)
      } else {
        console.log('setting as user not exists')
        navigate('/dashboard/settings')
        dashboardResp = null
      }

      if (
        dashboardResp !== undefined &&
        dashboardResp !== null
      ) {
        dashboardResp.recent_plays.forEach((x) => {
          x.refresh = plsRefresh
        })
        setRecentlyClosedPlays(dashboardResp.recent_plays)
        dashboardResp.recently_opened_plays.forEach((x) => {
          x.refresh = plsRefresh
        })
        setRecentlyOpenedPlays(dashboardResp.recently_opened_plays)
        setTotalGains(dashboardResp.total_gains)


        let result =
          parseFloat(dashboardResp.total_gains.cash) +
          parseFloat(
            (
              parseFloat(dashboardResp.total_gains.ETH) *
              dashboardResp.exchange_prices.ETH
            ).toFixed(2)
          ) +
          parseFloat(
            (
              parseFloat(dashboardResp.total_gains.DOGE) *
              dashboardResp.exchange_prices.DOGE
            ).toFixed(2)
          ) +
          parseFloat(
            (
              parseFloat(dashboardResp.total_gains.BTC) *
              dashboardResp.exchange_prices.BTC
            ).toFixed(2)
          )
            +
            parseFloat(
                (
                    parseFloat(dashboardResp.total_gains.BCH) *
                    dashboardResp.exchange_prices.BCH
                ).toFixed(2)
            )
            +
            parseFloat(
                (
                    parseFloat(dashboardResp.total_gains.AVAX) *
                    dashboardResp.exchange_prices.AVAX
                ).toFixed(2)
            )
            +
            parseFloat(
                (
                    parseFloat(dashboardResp.total_gains.LINK) *
                    dashboardResp.exchange_prices.LINK
                ).toFixed(2)
            )
            +
            parseFloat(
                (
                    parseFloat(dashboardResp.total_gains.UNI) *
                    dashboardResp.exchange_prices.UNI
                ).toFixed(2)
            )
            +
            parseFloat(
                (
                    parseFloat(dashboardResp.total_gains.ETC) *
                    dashboardResp.exchange_prices.ETC
                ).toFixed(2)
            )
        setTotalGainsEver(result.toFixed(2))

        

      }

    }

    if (profile.accessToken !== '') {
      run()
    }
  }, [profile.accessToken,  refresh])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    console.log('in the onsubmit')
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const value: RobinHoodProfile = {
      robinhood_email: data.get('robinhood_email'),
      robinhood_pw: data.get('robinhood_pw'),
      robinhood_token: data.get('robinhood_token'),
      market_app: 'robinhood'

    }
    dashboardAPI
      .postUser(String( profile.accessToken), value)
      .then((x) => plsRefresh())
  }

  return (
    <>
      <AppBar position="fixed">
        <Box sx={{ margin: '8px' }} display={'flex'}>
          <Typography sx={{ marginLeft: '16px' }} variant="h4">
            Crypto Copilot
          </Typography>
        </Box>
      </AppBar>

      <Container>
        <Grid
          container
          spacing={1}
          justifyContent={'center'}
          component="main"
          sx={{ height: '65vh', marginTop: '58px' }}
        >
          {existinguser && !loading && (
            <>
              <Grid item xs={12} md={12}>
                <EditManyPlaysModal refresh={plsRefresh}></EditManyPlaysModal>

                <TotalGainsCard
                  totalGainsEver={totalGainsEver}
                  totalGainsCash={totalGains.cash}
                  plsRefresh={plsRefresh}
                  setToLoading={setToLoading}
                  cryptoGains={{
                    ETH: {
                      totalGainsInCrypto: totalGains.ETH,
                      totalGainsInUSD: (
                        parseFloat(totalGains.ETH) * exchangePrices.ETH
                      ).toFixed(2)
                    },
                    DOGE: {
                      totalGainsInCrypto: totalGains.DOGE,
                      totalGainsInUSD: (
                        parseFloat(totalGains.DOGE) * exchangePrices.DOGE
                      ).toFixed(2)
                    },
                    AVAX: {
                      totalGainsInCrypto: totalGains.AVAX,
                      totalGainsInUSD: (
                        parseFloat(totalGains.AVAX) * exchangePrices.AVAX
                      ).toFixed(2)
                    },
                    XLM: {
                      totalGainsInCrypto: totalGains.XLM,
                      totalGainsInUSD: (
                        parseFloat(totalGains.XLM) * exchangePrices.XLM
                      ).toFixed(2)
                    },
                    BCH: {
                      totalGainsInCrypto: totalGains.BCH,
                      totalGainsInUSD: (
                        parseFloat(totalGains.BCH) * exchangePrices.BCH
                      ).toFixed(2)
                    },
                    LINK: {
                      totalGainsInCrypto: totalGains.LINK,
                      totalGainsInUSD: (
                        parseFloat(totalGains.LINK) * exchangePrices.LINK
                      ).toFixed(2)
                    },
                    UNI: {
                      totalGainsInCrypto: totalGains.UNI,
                      totalGainsInUSD: (
                        parseFloat(totalGains.UNI) * exchangePrices.UNI
                      ).toFixed(2)
                    },
                    ETC: {
                      totalGainsInCrypto: totalGains.ETC,
                      totalGainsInUSD: (
                        parseFloat(totalGains.ETC) * exchangePrices.ETC
                      ).toFixed(2)
                    },
                    BTC: {
                      totalGainsInCrypto: totalGains.BTC,
                      totalGainsInUSD: (
                        parseFloat(totalGains.BTC) * exchangePrices.BTC
                      ).toFixed(2)
                    }
                  }}
                />
              </Grid>


              <Grid item xs={12}>
                <Accordion sx={{ borderRadius: '25px !important' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" component="h5">
                      Recently Closed Plays
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {recentlyClosedPlays.map((o) => (
                      <PlayListItem key={o.id} {...o} />
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion sx={{ borderRadius: '25px !important' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" component="h5">
                      Recently Opened Plays
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {recentlyOpenedPlays.map((o) => (
                        <PlayListItem key={o.id} {...o} />
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </>
          )}

          {loading && <LoadingSkeletonCard />}
          <AppBarWithDrawer
            toggleAddFundModal={() => setOpenAddFundModel(!openAddFundModel)}
            showFundAndPlayButtons={true}
          />
        </Grid>
      </Container>
      <AddFundModal
        refresh={plsRefresh}
        isOpen={openAddFundModel}
        handleClose={() => setOpenAddFundModel(false)}
      />
    </>
  )
}

export default Dashboard

export interface DashboardStatusEntity {
  recent_plays: PlayEntity[]
  recently_opened_plays: PlayEntity[]
  total_gains: TotalGains
  exchange_prices: ExchangePrices
  robinhood_email: string
  crypto_invest_settings: CryptoInvestSettings
  bet_setting: string
  market_apps: MarketApps
}

export interface MarketApps{
  apps: string[]
}
export interface CryptoInvestSettings {
  ETH: string
  BTC: string
  DOGE: string
  AVAX: string


  XLM: string
  BCH: string
  LINK: string
  UNI: string

  ETC: string
}
interface TotalGains {
  ETH: string
  BTC: string
  DOGE: string
  cash: string
  AVAX: string
  XLM: string
  BCH: string
  LINK: string
  UNI: string
  ETC: string
}
export interface ExchangePrices {
  ETH: number
  DOGE: number
  BTC: number
  AVAX: number


  XLM: number
  BCH: number
  LINK: number
  UNI: number

  ETC: number
}

