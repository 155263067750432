import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../hooks'
import { dashboardAPI } from './DashboardAPI'
import DeleteIcon from '@mui/icons-material/Delete'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { AppBarWithDrawer } from '../shared/AppBarWithDrawer'
import {useNavigate} from "react-router-dom";
import {isTokenExpired} from "../utils";
import {reset} from "./dashboardSlice";
import {cryptoCopilotLocalStorageKey} from "../App";
import {useDispatch} from "react-redux";

export interface FundEntity {
  user: string
  status: 'in use' | 'open'
  id: string
  asset: string
  amount_in_usd: number
  amount_in_crypto: string
  amount_in_mxn: number
  updateFunds(): void
}
export interface MarketList {
  markets: string[]
  market_list: string[]
  purpose_usd: string[]
}
export interface FundEntityForPostRequest {
  status: 'in use' | 'open'
  asset: string | null | FormDataEntryValue
  amount_in_fiat_currency: number | FormDataEntryValue | null,
  number_of_funds_to_make: number | FormDataEntryValue | null
  market_app: string | null | FormDataEntryValue
  currency: string | null
  fund_expiring_setting: string | null | FormDataEntryValue
}

function FundCard({
  status,
  id,
  asset,
  amount_in_usd,
  updateFunds
}: FundEntity) {
  const { profile } = useAppSelector(state => state.dashboard)
  return (
    <Card raised sx={{ width: '350px', marginBottom: '16px' }}>
      <CardHeader title={`${asset} - ${amount_in_usd}`} />
      <CardContent>
        <Typography display={'block'} gutterBottom>
          Status: {status}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() =>
            dashboardAPI
              .deleteFund( profile.accessToken, id)
              .then(x => updateFunds())
          }
        >
          Delete Fund
        </Button>
        <Button
          variant="contained"
          color="success"
          startIcon={<AttachMoneyIcon />}
          onClick={() => console.log('add fund clicked id ', id)}
        >
          Add Fund
        </Button>
      </CardActions>
    </Card>
  )
}

export default function Funds() {
  const { profile } = useAppSelector(state => state.dashboard)
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const [funds, setFunds] = useState<{
    inuse: FundEntity[]
    open: FundEntity[]
  }>({ inuse: [], open: [] })

  function resetFunds() {
    setFunds({ inuse: [], open: [] })
  }
  const handleSignOut = () => {
    dispatch(reset())
    localStorage.removeItem(cryptoCopilotLocalStorageKey)
    navigate('/')
  }

  useEffect(() => {
    async function getFunds() {
      if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
        handleSignOut()
      }
      const res2 = undefined;
      const fundsData: FundEntity[] = res2 === undefined ? [] : [];
      console.log('getFunds res2', fundsData)
      if (fundsData.length > 0) {
        const inuse = fundsData
          .filter(f => f.status === 'in use')
          .map(f => {
            f.updateFunds = resetFunds
            return f
          })
        const open = fundsData
          .filter(f => f.status === 'open')
          .map(f => {
            f.updateFunds = resetFunds
            return f
          })
        setFunds({ inuse, open })
      }
    }
    getFunds()
  }, [profile.accessToken])

  return (
    <Container sx={{ marginTop: '65px' }} maxWidth="xl">
      <>
        <AppBarWithDrawer showFundAndPlayButtons={false} />
        <Grid component="main" container spacing={1}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4" display={'block'}>
              Opens Funds
            </Typography>
          </Grid>
          {funds.open.map(o => (
            <Grid key={o.id} item md={3} xs={12}>
              <FundCard {...o} />
            </Grid>
          ))}
          <Divider />
          <Grid item xs={12}>
            <Typography gutterBottom variant="h4" display={'block'}>
              In Use Funds
            </Typography>
          </Grid>
          {funds.inuse.map(o => (
            <Grid key={o.id} item md={3} xs={12}>
              <FundCard {...o} />
            </Grid>
          ))}
        </Grid>
      </>
    </Container>
  )
}
