import axios, { AxiosResponse } from 'axios'
import {FundEntity, FundEntityForPostRequest} from "./Funds";
import {OnDemandPlay} from "../dashboard/MakePlay";
import {EditManyPlays, EditPlay} from "../shared/EditPlayModal";

const possible_suffixes = ['_AVAX', '_BCH', '_BTC', '_ETC', '_ETH', '_DOGE', '_ETH' , '_UNI' ,'_LINK', '']
const BASE_URL =
  'https://us-central1-demolisherapp.cloudfunctions.net/crypto_bot_http_backend'
let current_suffix : string = '';
function getBaseURL() {
  let randomElement = possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];

  if(current_suffix === randomElement) {
    randomElement = possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];

  }
  current_suffix = randomElement;


  return BASE_URL + current_suffix;
  //return 'http://127.0.0.1:5000';


}
export interface RobinHoodProfile {
  robinhood_email: FormDataEntryValue | null
  robinhood_pw: FormDataEntryValue | null
  robinhood_token: FormDataEntryValue | null
  market_app: string
}

export interface BitsoProfile {
  bitso_email: FormDataEntryValue | null
  bitso_api_key: FormDataEntryValue | null
  bitso_api_secret: FormDataEntryValue | null
  market_app: string
}


export interface CustomMarketList {
  market_list: string[],
  purpose_usd: string[]

}
export interface ChangeCryptoInvestMode {
  crypto: string
  invest_mode: string
}
export interface ChangeGlobalInvestMode {

  bet_setting: string
}
export interface GoogleTokenRequest {

  g_csrf_token: string
}
export interface AccessTokenResponse {
  id_token: string
  expires: number
}
// checkUser checks if the user has an account with us or not
async function checkUser(tokenId: string) {
  try {
    const response = await axios.get(`${getBaseURL()}/check_user`, {
      headers: {
        'google-token-id': tokenId
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}



async function postFunds( tokenId: string, data: FundEntityForPostRequest ) {
  try {
    const json = JSON.stringify(data);
    console.log("post funds ");
    console.log(json);
    console.log("post funds ");

    const response = await axios.post(`${getBaseURL()}/post_funds`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

async function makePlay( tokenId: string, data: OnDemandPlay ) {
  try {
    const json = JSON.stringify(data);
    console.log("make play ");
    console.log(json);
    console.log("make play ");

    const response = await axios.post(`${getBaseURL()}/make_play`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

async function editPlay( tokenId: string, data: EditPlay ) {
  try {
    const json = JSON.stringify(data);
    console.log("make play ");
    console.log(json);
    console.log("make play ");

    const response = await axios.post(`${getBaseURL()}/post_play/${data.id}`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}


async function postBetSetting( tokenId: string, bet_setting: string) {
  try {
    const data: ChangeGlobalInvestMode = {
      bet_setting : bet_setting
    }

    const json = JSON.stringify(data);
    console.log("make play ");
    console.log(json);
    console.log("make play ");

    const response = await axios.post(`${getBaseURL()}/post_bet_settings`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}


async function getGoogleTokenAndExpire( tokenId: string) {
  try {
    const data: GoogleTokenRequest = {
      g_csrf_token : tokenId
    }

    const json = JSON.stringify(data);
    console.log("make play ");
    console.log(json);
    console.log("make play ");

    const response = await axios.post(`${getBaseURL()}/get_token`,json, {headers: {'Content-Type': 'text/plain'}})
    return response
  } catch (error) {
    console.error(error)
  }
}

async function postCryptoInvestSettings( tokenId: string, crypto: string, invest_mode: string) {
  try {
    const data: ChangeCryptoInvestMode = {
      crypto: crypto,
      invest_mode : invest_mode
    }

    const json = JSON.stringify(data);
    console.log("make play ");
    console.log(json);
    console.log("make play ");

    const response = await axios.post(`${getBaseURL()}/post_crypto_invest_setttings`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

async function editManyPlays( tokenId: string, data: EditManyPlays ) {
  try {
    const json = JSON.stringify(data);
    console.log("make play ");
    console.log(json);
    console.log("make play ");

    const response = await axios.post(`${getBaseURL()}/apply_mass_play_update`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

async function postUser( tokenId: string, data: RobinHoodProfile|BitsoProfile ) {
  try {
    const json = JSON.stringify(data);
    console.log("post user ");
    console.log(json);
    console.log("post user ");

    const response = await axios.post(`${getBaseURL()}/post_user`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getFunds( tokenId: string, market_app: string, asset: string) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_funds/${market_app}/${asset}`, {
      headers: {
        'google-token-id': tokenId
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

async function deleteFund( tokenId: string, fund_id: string) {
  try {
    const response = await axios.get(`${getBaseURL()}/delete_funds/${fund_id}`, {
      headers: {
        'google-token-id': tokenId
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

export type Asset = 'ETH' | 'BTC' | 'DOGE' | 'cash' | 'AVAX' | 'ETC' | 'XLM' | 'BCH' | 'LINK' | 'UNI'
async function getPlays( asset: Asset, tokenId: string) {
  try {
    const response = await axios.get(
      `${getBaseURL()}/get_plays/${asset}`,
      {
        headers: {
          'google-token-id': tokenId
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getMarketApps( tokenId: string) {
  try {
    const response = await axios.get(
        `${getBaseURL()}/get_market_apps`,
        {
          headers: {
            'google-token-id': tokenId
          }
        }
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getPlay( playId: string | undefined) {
  try {
    const response = await axios.get(
        `${getBaseURL()}/get_play/${playId}`,
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getCryptoInvestSetttings( tokenId: string) {
  try {
    const response = await axios.get(
        `${getBaseURL()}/get_crypto_invest_settings`,
        {
          headers: {
            'google-token-id': tokenId
          }
        }
    )
    return response
  } catch (error) {
    console.error(error)
  }
}


async function getExchangePrices() {
  try {
    const response = await axios.get(
        `${getBaseURL()}/get_exchange_prices`,
    )
    return response
  } catch (error) {
    console.error(error)
  }
}





async function refreshPlays( tokenId: string) {
  try {

    const response = await axios.get(`${getBaseURL()}/refresh_plays`, {
          headers: {
            'google-token-id': tokenId,
            'Content-Type': 'text/plain'
          }
        }
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

async function getMarkets( tokenId: string) {
  try {

    const response = await axios.get(`${getBaseURL()}/get_custom_market_list`, {
          headers: {
            'google-token-id': tokenId,
            'Content-Type': 'text/plain'
          }
        }
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

async function postMarket( tokenId: string, data: CustomMarketList ) {
  try {
    const json = JSON.stringify(data);


    const response = await axios.post(`${getBaseURL()}/post_market_list`,json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain'
      }
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

export const dashboardAPI = {
  checkUser,
  getFunds,
  getPlays,
  deleteFund,
  postUser,
  postFunds,
  makePlay,
  editPlay,
  getExchangePrices,
  getMarkets,
  postMarket,
  editManyPlays,
  postCryptoInvestSettings,
  refreshPlays,
  postBetSetting,
  getGoogleTokenAndExpire,
  getMarketApps,
  getCryptoInvestSetttings,
  getPlay
}
