import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Add'
import {
    Chip,
    CircularProgress,
    Fab, InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material'
import { Asset, dashboardAPI } from '../dashboard/DashboardAPI'
import { useAppSelector } from '../hooks'
import { FundEntity } from '../dashboard/Funds'
import {useNavigate} from "react-router-dom";
import {isTokenExpired} from "../utils";
import {reset} from "../dashboard/dashboardSlice";
import {cryptoCopilotLocalStorageKey} from "../App";
import {useDispatch} from "react-redux";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
}

export interface EditPlay {
    id: string
    new_ending_price: null | FormDataEntryValue
    change_exit_price: boolean
}

export interface EditManyPlays {
    play: string
    new_ending_price: string
    crypto_symbol: string
    market_app: string
}


function BearMenuItem({ amount_in_crypto, id, amount_in_usd }: FundEntity) {
    if (
        amount_in_crypto === undefined ||
        amount_in_crypto === null ||
        amount_in_crypto === ''
    ) {
        return (
            <MenuItem key={id} value={id}>
                {amount_in_usd}
            </MenuItem>
        )
    }
    return (
        <MenuItem key={id} value={id}>
            {amount_in_crypto}
        </MenuItem>
    )
}
BearMenuItem.defaultProps = {
    className: 'menu-item'
}

export interface RefreshLink {
    refresh(): void
}


export default function EditManyPlaysModal({refresh}: RefreshLink) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { exchangePrices, marketApps } = useAppSelector(state => state.dashboard)
    const [open, setOpen] = React.useState<boolean>(false)
    const [marketApp, setMarketApp] = React.useState<string>( marketApps.apps.length > 0 ? marketApps.apps[0] : 'robinhood')

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [playType, setPlayType] = React.useState<string>('bull')
    const [cryptoSymbol, setCryptoSymbol] = React.useState<string>('ETH')
    const [newExitPrice, setNewExitPrice] = React.useState<string>((exchangePrices.ETH*1.05).toString())

    const [formValid, setFormValid] = React.useState<boolean>(true);



    const { profile } = useAppSelector(state => state.dashboard)



    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleMarketAppSelect = (
        event: React.MouseEvent<HTMLElement>,
        newFormats: string
    ) => {
        setMarketApp(newFormats)
    }


    const updateNewExitPrice = (playType2 : string, cryptoSymbol2: string) => {
        if(playType2 === 'bull') {
            if(cryptoSymbol2 === 'ETH') {
                setNewExitPrice((exchangePrices.ETH*1.07).toString())
            }

            if(cryptoSymbol2 === 'DOGE') {
                setNewExitPrice((exchangePrices.DOGE*1.07).toString())
            }

            if(cryptoSymbol2 === 'BTC') {
                setNewExitPrice((exchangePrices.BTC*1.07).toString())
            }

            if(cryptoSymbol2 === 'AVAX') {
                setNewExitPrice((exchangePrices.AVAX*1.07).toString())
            }


            if(cryptoSymbol2 === 'XLM') {
                setNewExitPrice((exchangePrices.XLM*1.07).toString())
            }

            if(cryptoSymbol2 === 'BCH') {
                setNewExitPrice((exchangePrices.BCH*1.07).toString())
            }


            if(cryptoSymbol2 === 'LINK') {
                setNewExitPrice((exchangePrices.LINK*1.07).toString())
            }

            if(cryptoSymbol2 === 'UNI') {
                setNewExitPrice((exchangePrices.UNI*1.07).toString())
            }



            if(cryptoSymbol2 === 'ETC') {
                setNewExitPrice((exchangePrices.ETC*1.07).toString())
            }
        }
        if(playType2 === 'bear') {
            if(cryptoSymbol2 === 'ETH') {
                setNewExitPrice((exchangePrices.ETH*0.94).toString())
            }

            if(cryptoSymbol2 === 'DOGE') {
                setNewExitPrice((exchangePrices.DOGE*0.94).toString())
            }

            if(cryptoSymbol2 === 'BTC') {
                setNewExitPrice((exchangePrices.BTC*0.94).toString())
            }

            if(cryptoSymbol2 === 'AVAX') {
                setNewExitPrice((exchangePrices.AVAX*0.94).toString())
            }


            if(cryptoSymbol2 === 'XLM') {
                setNewExitPrice((exchangePrices.XLM*0.94).toString())
            }


            if(cryptoSymbol2 === 'BCH') {
                setNewExitPrice((exchangePrices.BCH*0.94).toString())
            }


            if(cryptoSymbol2 === 'LINK') {
                setNewExitPrice((exchangePrices.LINK*0.94).toString())
            }


            if(cryptoSymbol2 === 'UNI') {
                setNewExitPrice((exchangePrices.UNI*0.94).toString())
            }




            if(cryptoSymbol2 === 'ETC') {
                setNewExitPrice((exchangePrices.ETC*0.94).toString())
            }
        }
    }


    const handleChangePlayType = (event: SelectChangeEvent) => {

        setPlayType(event.target.value)
        updateNewExitPrice(event.target.value, cryptoSymbol)
    }
    const handleChangeCryptoSymbol = (event: SelectChangeEvent) => {


        setCryptoSymbol(event.target.value)
        updateNewExitPrice(playType, event.target.value)
    }
    const handleSignOut = () => {
        dispatch(reset())
        localStorage.removeItem(cryptoCopilotLocalStorageKey)
        navigate('/')
    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true)
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const value: EditManyPlays = {
            crypto_symbol: cryptoSymbol,
            new_ending_price: newExitPrice,
            play: playType,
            market_app: marketApp
        }
        console.log('this is the data');
        console.log(value);
        console.log('this is the data');

        if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
            handleSignOut()
        }

        dashboardAPI.editManyPlays( String( profile.accessToken), value).then(x => {
            setIsLoading(false)

            handleClose()
            refresh()

        })
    }
    return (
        <div>
            <Fab
                onClick={handleOpen}
                variant="extended"
                color="secondary"
                aria-label="add"
            > Update Many Plays
                <EditIcon />
            </Fab>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box
                    sx={{ ...style, width: 400 }}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Typography sx={{ marginLeft: '16px' }} variant="h4">
                        Edit Many Plays
                    </Typography>


                    <InputLabel id="label-market">Market App</InputLabel>
                    {marketApps.apps.length === 1 ?
                        <Chip label={marketApp}/>
                        :
                        <ToggleButtonGroup
                            color="primary"
                            value={marketApp}
                            exclusive
                            onChange={handleMarketAppSelect}
                            id="market_app"
                            aria-labelledby={'label-market'}
                            size={"small"}
                        >
                            <ToggleButton disabled={!marketApps.apps.includes('robinhood')} value="robinhood">Robinhood</ToggleButton>
                            <ToggleButton disabled={!marketApps.apps.includes('bitso')} value="bitso">Bitso</ToggleButton>
                        </ToggleButtonGroup>
                    }


                    <InputLabel id="label">Crypto</InputLabel>

                    <Select
                        labelId="label"
                        id="crypto-symbol"
                        name="crypto-symbol"
                        value={cryptoSymbol}
                        defaultValue={cryptoSymbol}
                        onChange={handleChangeCryptoSymbol}
                        fullWidth
                    >
                        <MenuItem value={'ETH'}>ETH</MenuItem>
                        <MenuItem value={'BTC'}>BTC</MenuItem>
                        <MenuItem value={'DOGE'}>DOGE</MenuItem>
                        <MenuItem value={'AVAX'}>AVAX</MenuItem>



                        <MenuItem value={'XLM'}>XLM</MenuItem>
                        <MenuItem value={'BCH'}>BCH</MenuItem>
                        <MenuItem value={'LINK'}>LINK</MenuItem>
                        <MenuItem value={'UNI'}>UNI</MenuItem>
                        <MenuItem value={'ETC'}>ETC</MenuItem>
                    </Select>

                    <InputLabel id="label-play-type">Play Type</InputLabel>

                    <Select
                        labelId="play-play-type"
                        id="play"
                        name="play"
                        value={playType}
                        defaultValue={playType}
                        label="Play Type"
                        onChange={handleChangePlayType}
                        fullWidth
                    >
                        <MenuItem value={'bull'}>Bull</MenuItem>
                        <MenuItem value={'bear'}>Bear</MenuItem>


                    </Select>



                    { cryptoSymbol === 'DOGE' ? <p>DOGE Market Price: {exchangePrices.DOGE}</p> : <></> }
                    { cryptoSymbol === 'BTC' ? <p>BTC Market Price: {exchangePrices.BTC}</p>: <></> }
                    { cryptoSymbol === 'ETH' ?  <p>ETH Market Price: {exchangePrices.ETH}</p> : <></> }
                    { cryptoSymbol === 'AVAX' ?  <p>AVAX Market Price: {exchangePrices.AVAX}</p> : <></> }

                    { cryptoSymbol === 'ETC' ? <p>ETC Market Price: {exchangePrices.ETC}</p> : <></> }


                    { cryptoSymbol === 'XLM' ? <p>XLM Market Price: {exchangePrices.XLM}</p> : <></> }
                    { cryptoSymbol === 'BCH' ? <p>BCH Market Price: {exchangePrices.BCH}</p> : <></> }
                    { cryptoSymbol === 'LINK' ? <p>LINK Market Price: {exchangePrices.LINK}</p> : <></> }
                    { cryptoSymbol === 'UNI' ? <p>UNI Market Price: {exchangePrices.UNI}</p> : <></> }


                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="new_ending_price"
                        label="New Exit Price"
                        id="new_ending_price"
                        type="number"
                        value={newExitPrice}
                        inputProps={{ step: '0.01', lang: 'en-US' }}
                        onChange={ e => {setNewExitPrice(e.target.value);}}
                        // error={ newExitPrice === "" || (starting_transaction_type === 'buy' && parseFloat(newExitPrice) < parseFloat(price) ) ||
                        //     (starting_transaction_type === 'sell' && parseFloat(newExitPrice) > parseFloat(price) )}
                        //
                        // helperText={starting_transaction_type === 'buy' && parseFloat(newExitPrice) < parseFloat(price) ? 'For Bull Plays, exit price must be higher than entry price' :
                        //     starting_transaction_type === 'sell' && parseFloat(newExitPrice) > parseFloat(price) ? 'For Bear Plays, exit price must be higher than entry price' : ' '}

                    />




                    <Button
                        fullWidth
                        disabled={isLoading || !formValid}
                        startIcon={isLoading && <CircularProgress color={'secondary'} />}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Update Many Plays
                    </Button>
                </Box>
            </Modal>
        </div>
    )
}
