import {
  Typography,
  Container,
  Paper, AlertTitle, Alert
} from '@mui/material'
import React, {useEffect} from 'react'
import { dashboardAPI} from './DashboardAPI'
import { useAppSelector } from '../hooks'
import { AppBarWithDrawer } from '../shared/AppBarWithDrawer'
import { useDispatch } from 'react-redux'
import {reset} from './dashboardSlice'
import {useNavigate, useParams} from "react-router-dom";
import {isTokenExpired} from "../utils";
import {PlayEntity} from "./Plays";
import {cryptoCopilotLocalStorageKey} from "../App";
import PlayListItem from "../shared/PlayListItem";
import Skeleton from "@mui/material/Skeleton";

type Props = {}

export default function Play({}: Props) {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { profile, cryptoInvestSettings, marketApps } = useAppSelector(
    (state) => state.dashboard
  )
  const {id } = useParams()
  ;
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [startingTransactionStatus, setStartingTransactionStatus] = React.useState<string>('')
  const [endingTransactionStatus, setEndingTransactionStatus] = React.useState<string>('')
  const [startingTransactionVariant, setStartingTransactionVariant] = React.useState<'standard' | 'filled' | 'outlined'>('standard')
  const [endingTransactionVariant, setEndingTransactionVariant] = React.useState<'standard' | 'filled' | 'outlined'>('standard')
  const [endingTransactionAmount, setEndingTransactionAmount] = React.useState<string>('')
  const [play, setPlay] = React.useState<PlayEntity>(
  {
    amount_in_usd: 0.0,
    amount_in_mxn: '0.0',
    create_ts: '',
    crypto_symbol: '',
    ending_asset: '',
    ending_price: 0.0,
    ending_quantity: '',
    ending_transaction: '',
    funding_id: '',
    id: '',
    last_upd_ts: '',
    price: '',
    quantity: '',
    starting_order_has_been_fulfilled: false,
    starting_transaction: '',
    starting_transaction_type: '',
    status: '',
    updated_price_on_order_fulfilment: false,
    user: '',
    gains: 0.0,
    gains_string: '',
    gains_asset: '',
    gains_in_usd: 0.0,
    play_source_label: '',
    market_app: '',

    refresh(): void{ }
  }
  );

  const handleSignOut = () => {
    dispatch(reset())
    localStorage.removeItem(cryptoCopilotLocalStorageKey)
    navigate('/')
  }

  useEffect(() => {
    async function getPlay() {
      setIsLoading(true);
      if(profile.accessTokenExpiresAt !== null && isTokenExpired(profile.accessTokenExpiresAt)){
        handleSignOut()
      }

        const res2 = await dashboardAPI.getPlay(
           id
        )
        const play: PlayEntity = res2 === undefined ? {
          amount_in_usd: 0.0,
          amount_in_mxn: '0.0',
          create_ts: '',
          crypto_symbol: '',
          ending_asset: '',
          ending_price: 0.0,
          ending_quantity: '',
          ending_transaction: '',
          funding_id: '',
          id: '',
          last_upd_ts: '',
          price: '',
          quantity: '',
          starting_order_has_been_fulfilled: false,
          starting_transaction: '',
          starting_transaction_type: '',
          status: '',
          updated_price_on_order_fulfilment: false,
          user: '',
          gains: 0.0,
          gains_string: '',
          gains_asset: '',
          gains_in_usd: 0.0,
          play_source_label: '',
          market_app: '',

          refresh(): void{ }
        } : res2.data;
      let startingTransactionStatus2 = ''
      let startingTransactionVariant2 : 'standard' | 'filled' | 'outlined' = 'standard';
      if(play.detailed_status !== undefined && play.detailed_status.starting_transaction_has_been_fulfilled !== undefined){
        startingTransactionStatus2 = 'Order Fulfilled';
        startingTransactionVariant2 = 'filled';
      } else if(play.detailed_status !== undefined && play.detailed_status.starting_transaction_submitted !== undefined){
        startingTransactionStatus2 = 'Order Submitted';
        startingTransactionVariant2 = 'outlined'
      } else {
        startingTransactionStatus2 = "Awaiting Order Submittal"
        startingTransactionVariant2 = 'standard'
      }
      setStartingTransactionStatus(startingTransactionStatus2)
      setStartingTransactionVariant(startingTransactionVariant2)

      let endingTransactionStatus2 = ''
      let endingTransactionVariant2 : 'standard' | 'filled' | 'outlined' = 'standard';


      if(play.detailed_status !== undefined && play.detailed_status.ending_transaction_has_been_fulfilled !== undefined){
        endingTransactionStatus2 = 'Order Fulfilled';
        endingTransactionVariant2 = 'filled';

      } else if(play.detailed_status !== undefined && play.detailed_status.ending_transaction_submitted !== undefined){
        endingTransactionStatus2 = 'Order Submitted';
        endingTransactionVariant2 = 'outlined';

      } else {
        endingTransactionStatus2 = "First Transaction Must Occur"
        endingTransactionVariant2 = 'standard';

      }

      let endingTransactionAmount2 = ''
      if(play.starting_transaction_type === 'buy') {
        if(play.market_app === 'robinhood'){
        endingTransactionAmount2 = (play.amount_in_usd*(play.ending_price/parseFloat(play.price))).toFixed(2) + " USD"
        }
        if(play.market_app === 'bitso'){
          endingTransactionAmount2 = (parseFloat(play.amount_in_mxn)*(play.ending_price/parseFloat(play.price))).toFixed(2) + " MXN"
        }
      }
      if(play.starting_transaction_type === 'sell') {
        if(play.market_app === 'robinhood'){
          endingTransactionAmount2 = (play.amount_in_usd*(parseFloat(play.price)/play.ending_price)).toFixed(2) + " USD"
        }
        if(play.market_app === 'bitso'){
          endingTransactionAmount2 = (parseFloat(play.amount_in_mxn)*(parseFloat( play.price)/play.ending_price)).toFixed(2) + " MXN"
        }
      }

    setEndingTransactionAmount(endingTransactionAmount2)
      setEndingTransactionStatus(endingTransactionStatus2)
      setEndingTransactionVariant(endingTransactionVariant2)
        setPlay(play);
      setIsLoading(false);
    }

    getPlay()

  }, []);






  return (
    <Container>
      {id}
      {!isLoading ? <>
      <PlayListItem key={play.id} {...play} />


      <Paper >
        <Typography variant={"h5"}>Starting Transaction</Typography>
        <Alert icon={false} severity={ play.starting_transaction_type === 'buy' ? "success" : "error"} variant={startingTransactionVariant} >
          <AlertTitle>{play.starting_transaction_type === 'buy' ? 'BUY' : 'SELL'} {parseFloat(play.quantity).toFixed(6)} {play.crypto_symbol} @{parseFloat(play.price).toFixed(4)} -- {(parseFloat( play.price) * parseFloat(play.quantity)).toFixed(2)} {play.market_app === 'robinhood' ? 'USD' : 'MXN'}</AlertTitle>
          {startingTransactionStatus}

        </Alert>
      </Paper>


      <Paper >
        <Typography variant={"h5"}>Ending Transaction</Typography>
        <Alert icon={false} severity={ play.starting_transaction_type === 'buy' ? 'error' : "success"} variant={endingTransactionVariant} >
          {endingTransactionStatus === 'First Transaction Must Occur'?  <AlertTitle>{play.starting_transaction_type === 'buy' ? 'SELL' : 'BUY'} {play.crypto_symbol} {endingTransactionAmount} @{play.ending_price.toFixed(4)}</AlertTitle> : <AlertTitle>{play.starting_transaction_type === 'buy' ? 'SELL' : 'BUY'} {parseFloat( play.ending_quantity).toFixed(6)} {play.crypto_symbol} @{play.ending_price.toFixed(4)} -- {(play.ending_price * parseFloat(play.ending_quantity)).toFixed(2)} {play.market_app === 'robinhood' ? 'USD' : 'MXN'}</AlertTitle>}
          {endingTransactionStatus}

        </Alert>
      </Paper>
      </>: <Skeleton animation="wave" />}

      <AppBarWithDrawer showFundAndPlayButtons={false} />
    </Container>
  )
}
