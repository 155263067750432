import React from 'react'
import './App.css'
import SignInSide from './login/SignInSide'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ThemeProvider } from '@mui/material/styles'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import Funds from './dashboard/Funds'
import Plays from './dashboard/Plays'
import { useAppDispatch, useAppSelector } from './hooks'
import {
  Profile,
  reset, setCryptoInvestSettings,
  setDarkMode,
  setExchangePrices, setMarketApps,
  setProfile
} from './dashboard/dashboardSlice'
import { isTokenExpired } from './utils'

import { CssBaseline } from '@mui/material'
import { getTheme } from './theme'
import { AccessTokenResponse, dashboardAPI } from './dashboard/DashboardAPI'
import Settings from './dashboard/Settings'
import Cookies from 'js-cookie'

import { GoogleOAuthProvider } from '@react-oauth/google'
import MakePlay from "./dashboard/MakePlay";
import Play from "./dashboard/Play";

export const cryptoCopilotLocalStorageKey = 'crypto-copilot-user-profile'
export const clientId =
  '692772929154-ng5t09h3ekdph06rtek5muhm7uccj2em.apps.googleusercontent.com'

function App() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { profile } = useAppSelector((state) => state.dashboard)

  const { isDarkMode } = useAppSelector((state) => state.dashboard)

  const handleSuccess = (res: any) => {
    const resTyped = { ...res }
    console.log('APP success signin restyped', resTyped)
    console.log('APP success signin profileObj', resTyped.profileObj)

    const accessTokenExpiresAt = Date.now() + 4 * 3600 * 1000
    const accessToken = resTyped.accessToken

    dispatch(setProfile({ accessToken, accessTokenExpiresAt }))
    dashboardAPI
      .getExchangePrices()
      .then((x) =>
        dispatch(
          setExchangePrices(
            x === undefined ? { ETH: 0.0, DOGE: 0.0, BTC: 0.0 } : x.data
          )
        )
      )
  }

  React.useEffect(() => {
    dashboardAPI
      .getExchangePrices()
      .then((x) =>
        dispatch(
          setExchangePrices(
            x === undefined ? { ETH: 0.0, DOGE: 0.0, BTC: 0.0 } : x.data
          )
        )
      )

    if(profile.accessToken !== '') {
      dashboardAPI.getMarketApps(profile.accessToken).then( (x) =>
      dispatch(
          setMarketApps(
              x === undefined ? {apps: []} : x.data
          )

      )

    )
      dashboardAPI.getCryptoInvestSetttings(profile.accessToken).then( (x) =>
          dispatch(
              setCryptoInvestSettings(
                  x === undefined ? {
                    ETH: "crypto",
                    BTC: "crypto",
                    BCH: "crypto",
                    LINK: "crypto",
                    AVAX: "crypto",
                    XLM: "crypto",
                    UNI: "crypto",
                    ETC: "crypto",
                    DOGE: "crypto"
                  } : x.data
              )
          )
    )

    }



  }, [profile.accessToken])

  React.useEffect(() => {}, [clientId])

  React.useEffect(() => {
    async function figureOutAuth() {
      let g_csrf_token_var = Cookies.get('g_csrf_token')
      console.log(g_csrf_token_var)
      console.log(g_csrf_token_var)

      if (
        profile.accessToken &&
        profile.accessTokenExpiresAt &&
        !isTokenExpired(profile.accessTokenExpiresAt)
      ) {
        if (location.pathname === '/') {
          navigate('/dashboard')
        }
      }

      if (
        g_csrf_token_var !== undefined &&
        g_csrf_token_var !== null &&
        profile.accessToken === ''
      ) {
        const res = await dashboardAPI.getGoogleTokenAndExpire(
          // profile.email,
          g_csrf_token_var
        )

        const accessTokenResponse: AccessTokenResponse =
          res === undefined ? { id_token: '', expires: 0 } : res.data

        let accessTokenExpiresAt = accessTokenResponse.expires
        let accessToken = accessTokenResponse.id_token
        const profile2: Profile = { accessToken, accessTokenExpiresAt }





        if (profile.accessToken !== profile2.accessToken) {



          Cookies.remove('id_token')
          Cookies.remove('expires')
          console.log(JSON.stringify(profile2))
          console.log('thats what being saveddd')

          localStorage.setItem(
            cryptoCopilotLocalStorageKey,
            JSON.stringify(profile2)
          )

          dispatch(setProfile(profile2))
          // is current location at root? If so nav to dashboard
          if (location.pathname === '/') {
            navigate('/dashboard')
          }
        }
      } else {
        console.log('HEYYY IN LOC')
        console.log(profile.accessToken)
        console.log('HEYYY IN LOC')

        const localStorageProfileString = localStorage.getItem(
          cryptoCopilotLocalStorageKey
        )
        let tokenExpiresAt: number | null

        console.log('localStorageProfileString', localStorageProfileString)
        if (localStorageProfileString) {
          const localStorageProfile = JSON.parse(localStorageProfileString)
          tokenExpiresAt = localStorageProfile.accessTokenExpiresAt
          console.log('tokenExpiresAt', tokenExpiresAt)
          if (
            (tokenExpiresAt !== null && isTokenExpired(tokenExpiresAt)) ||
            localStorageProfile.accessToken === '' ||
            localStorageProfile.accessToken === null ||
            localStorageProfile.accessToken === undefined
          ) {
            console.log('token is expired??? -_-')
            localStorage.removeItem(cryptoCopilotLocalStorageKey)
            dispatch(reset())
            navigate('/')
            console.log('token is expired??? -_-')
          } else {
            console.log('token is being set')
            console.log(localStorageProfile.accessToken)
            dispatch(setProfile(localStorageProfile))
            if (location.pathname === '/') {
              navigate('/dashboard')
            }
          }
        }
        else{
          navigate('/')
        }
      }
    }

    figureOutAuth()
  }, [])

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  React.useEffect(() => {
    dispatch(setDarkMode(prefersDarkMode))
  }, [prefersDarkMode])

  const theme = React.useMemo(() => {
    dispatch(setDarkMode(isDarkMode))
    return getTheme(isDarkMode)
  }, [isDarkMode])
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <Routes>
            <Route path="/" element={<SignInSide />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/funds" element={<Funds />} />
            <Route path="/dashboard/plays" element={<Plays />} />
            <Route path="/dashboard/settings" element={<Settings />} />
            <Route path="/dashboard/make_play" element={<MakePlay />} />
            <Route path="/dashboard/play/:id" element={<Play />} />
          </Routes>
        </ThemeProvider>
      </div>
    </GoogleOAuthProvider>
  )
}

export default App
